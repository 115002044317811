import { List, Spin } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';
import { savequeries } from '../../store/User/KycSlice';
import { savequeryid } from '../../store/Queries/QueriesSlice';

const MobQueriesList = ({data,loading}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div className="w-full">
            <Spin spinning={loading} className="w-full">
                <div className="flex flex-col items-center gap-10">
                    <List
                        className='w-full'
                        size="large"
                        pagination={{
                            align:'center',
                            position:'both'
                        }}
                        dataSource={data?.queryResult}
                        renderItem={(item,i) => (
                        <List.Item key={i} className="px-0 py-5 border-none" onClick={()=>
                            {
                                dispatch(savequeryid(item.queryId))
                                navigate('queries-details')
                            }}>
                            <div className="w-full rounded-2xl shadow-cardsection bg-white">
                                <div className="p-6">
                                    <div className="grid grid-cols-12 gap-6 w-full">
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Freight Type</p>
                                                <p className="text-sm font-normal leading-4 break-all">{item?.cargoType}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Origin</p>
                                                <p className="text-sm font-normal leading-4 break-all">{item?.cargoType === 'Air Cargo' ?  item?.searchquery?.originAirport?.originAirport : item?.searchquery?.originShipment?.originShipment}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Destination</p>
                                                <p className="text-sm font-normal leading-4 break-all">{item?.cargoType === 'Air Cargo' ?  item?.searchquery?.destinationAirport?.destinationAirport : item?.searchquery?.destinationShipment?.destinationShipment}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Cargo Ready Date</p>
                                                <p className="text-sm font-normal leading-4 break-all">{item?.cargoreadydate ? item?.cargoreadydate : 'NA'}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Query Date</p>
                                                <p className="text-sm font-normal leading-4 break-all">{dayjs(item?.queryDate).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Commodity Type</p>
                                                <p className="text-sm font-normal leading-4 break-all">{item?.searchquery?.goodsDetails?.commonditytype?.commonditytype ? item?.searchquery?.goodsDetails?.commonditytype?.commonditytype : 'NA'}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex w-full items-center gap-5">
                                                <p className="text-sm font-semibold leading-4 w-1/3">Status</p>
                                                <p className={`text-sm font-semibold leading-4 break-all ${item.status === "New" ? "text-[#00A124]" : item.status === "Quoted" ? "text-primary" : "text-red-700"}`}>{item?.status}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <button type='button' className=' rounded-b-2xl w-full p-5 flex items-center gap-2 bg-primary hover:bg-secondary text-white shadow-editbtn justify-center'
                                onClick={ (e)=>{
                                    dispatch(savequeries(item))
                                    navigate('queries-details')
                                }}
                                >
                                    <span>View Details</span>
                                </button>
                            </div>
                        </List.Item>
                        )}
                    />
                </div>

            </Spin>
        </div>
    )
}

export default MobQueriesList