const IncoTerms = {
    imports : [
        {
            name : 'FOB',
            services : {
                originServices : [],
                destinationServices : [
                    {
                        name : 'Destination Cargo Handling',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Destination CFS Clearance',
                        icon : 'clearance'
                    }
                ]
            }
        },
        {
            name : 'EXW',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : [
                    {
                        name : 'Destination Cargo Handling',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Destination CFS Clearance',
                        icon : 'clearance'
                    }
                ]
            }
        },
        {
            name : 'FCA',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    }
                ],
                destinationServices : [
                    {
                        name : 'Destination Cargo Handling',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Destination CFS Clearance',
                        icon : 'clearance'
                    }
                ]
            }
        },
        {
            name : 'FAS',
            services : {
                originServices : [],
                destinationServices : [
                    {
                        name : 'Destination Cargo Handling',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Destination CFS Clearance',
                        icon : 'clearance'
                    }
                ]
            }
        }
    ],
    exports: [

        {
            name : 'CIF',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : []
            }
        },
        {
            name : 'CFR',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : []
            }
        },
        {
            name : 'CPT',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : []
            }
        },
        {
            name : 'CIP',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : []
            }
        },
        {
            name : 'DAT',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : []
            }
        },
        {
            name : 'DAP',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : [
                    {
                        name : 'Destination Cargo Handling',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Transportation',
                        icon : 'transport'
                    }
                ]
            }
        },
        {
            name : 'DDP',
            services : {
                originServices : [
                    {
                        name : 'Origin Cargo Stuffing',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Origin Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Origin CFS Clearance',
                        icon : 'clearance'
                    }
                ],
                destinationServices : [
                    {
                        name : 'Destination Cargo Handling',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Customs Clearance',
                        icon : 'clearance'
                    },
                    {
                        name : 'Destination Transportation',
                        icon : 'transport'
                    },
                    {
                        name : 'Destination CFS Clearance',
                        icon : 'clearance'
                    }
                ]
            }
        }
    ]
}

export { IncoTerms }