import React, { Suspense, lazy, useEffect, useState } from "react";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Popover } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import TextError from "../../TextError";

import { useDispatch, useSelector } from "react-redux";
import {
  getallairports,
  searchrates,
  searchratestate,
} from "../../../store/SearchRates/SearchRatesSlice";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { IncoTerms } from "../../../utils/incoTermsDatas";
import { Modal, Skeleton } from "antd";
const OriginAirport = lazy(() => import("./OriginAirport/OriginAirport"));
const DestinationAirport = lazy(() =>
  import("./DestinationAirport/DestinationAirport")
);
const GoodDetails = lazy(() => import("./GoodsDetails/GoodDetails"));
const PackagingDetails = lazy(() =>
  import("./PackagingDetails/PackagingDetails")
);
const PaymentTerms = lazy(() => import("./PaymentTerms/PaymentTerms"));

const FlightRate = ({ searchBy, searchFormData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [formikData, setFormikData] = useState(null);
  const [popForm, setPopForm] = useState();
  const [airports, setAirports] = useState([]);
  const [webopen, setWebOpen] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allAirportsState = useSelector(searchratestate);

  const handleClick = (event, formik, popform) => {
    console.log("handleclick",event,formik,popform)
    setAnchorEl(event.currentTarget);
    setFormikData(formik);
    setPopForm(popform);
    console.log(searchFormData,"yhbrt")
  };
  const handleClose = () => {
    setAnchorEl(null);
    setPopForm(null);
    console.log(popForm)
  };
  let specialcargo = {};
  if (
    searchFormData?.searchquery?.goodsDetails.commonditytype
      .commonditytypesub === "Dangerous Goods"
  ) {
    specialcargo = {
      dangerousgoods: {
        DGcommodityname: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.dangerousgoods?.DGcommodityname
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.dangerousgoods?.DGcommodityname
          : "",
        DGhscode: searchFormData?.searchquery?.goodsDetails.commonditytype
          ?.specialcargo?.dangerousgoods?.DGhscode
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.dangerousgoods?.DGhscode
          : "",
        DGclassdescription: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.dangerousgoods?.DGclassdescription
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.dangerousgoods?.DGclassdescription
          : "",
        DGpackinggroup: searchFormData?.searchquery?.goodsDetails.commonditytype
          ?.specialcargo?.dangerousgoods?.DGpackinggroup
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.dangerousgoods?.DGpackinggroup
          : "",
        DGspecialinstructions: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.dangerousgoods?.DGspecialinstructions
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.dangerousgoods?.DGspecialinstructions
          : "",
        DGmsdsfile: "",
        DGpackinglistfile: "",
      },
    };
  } else if (
    searchFormData?.searchquery?.goodsDetails.commonditytype
      .commonditytypesub === "Temperature Controlled / Pharma"
  ) {
    specialcargo = {
      temperaturecontrolled: {
        TCtempereaturecontroledtype: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.temperaturecontrolled
          ?.TCtempereaturecontroledtype
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.temperaturecontrolled?.TCtempereaturecontroledtype
          : "",
        TCtemperaturecontrolrange: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.temperaturecontrolled
          ?.TCtemperaturecontrolrange
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.temperaturecontrolled?.TCtemperaturecontrolrange
          : "",
        TCspecialinstrictions: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.temperaturecontrolled
          ?.TCspecialinstrictions
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.temperaturecontrolled?.TCspecialinstrictions
          : "",
        TCpackinglistfile: "",
      },
    };
  } else if (
    searchFormData?.searchquery?.goodsDetails.commonditytype
      .commonditytypesub === "Other Special Commodity"
  ) {
    specialcargo = {
      specialcommodity: {
        OSotherspecialcommoditytype: searchFormData?.searchquery?.goodsDetails
          .commonditytype?.specialcargo?.specialcommodity
          ?.OSotherspecialcommoditytype
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              ?.specialcargo?.specialcommodity?.OSotherspecialcommoditytype
          : "",
      },
    };
  }
  let PackagesInit = [];
  if (
    searchFormData?.searchquery?.packagingDetails?.packagingType?.packages
      ?.length > 0
  ) {
    searchFormData?.searchquery?.packagingDetails?.packagingType?.packages?.map(
      (item) => {
        console.log("1366666666666666666", item);
        let obj = {
          units: item.units ,
          length: item.length ,
          width: item.width ,
          height: item.height,
          unit: item.unit,
          weight: item.weight,
          weightunit: item.weightunit,
          packagetype: item.packagetype,
          handling: item.handling,
          totalsubunits: item.totalsubunits,
          totalsubweight: item.totalsubweight,
          totalsubvolume: item.totalsubvolume,
         
        };
        PackagesInit.push(obj);
        console.log(obj);
      }
    );
   
  } else {
    PackagesInit.push({
      units: "",
      length: "",
      width: "",
      height: "",
      unit: "CM",
      weight: "",
      weightunit: "KG/UNIT",
      packagetype: "Box",
      handling: "Stackable",
      totalsubunits: "",
      totalsubweight: "",
      totalsubvolume: "",
      Temp:"",
    });
  }

  const initialValues = {
    cargoType: searchBy,
    originAirport: {
      originAirport: searchFormData?.searchquery?.originAirport.originAirport
        ? searchFormData?.searchquery?.originAirport.originAirport
        : "",
      originLocation: searchFormData?.searchquery?.originAirport.originLocation
        ? searchFormData?.searchquery?.originAirport.originLocation
        : "",
      originPickup:
        searchFormData?.searchquery?.originAirport.originPickup === "1"
          ? true
          : false,
      originLocationMarker: searchFormData?.searchquery?.originAirport
        .originLocationMarker
        ? searchFormData?.searchquery?.originAirport.originLocationMarker
        : {},
      originPortMarker: searchFormData?.searchquery?.originAirport
        .originPortMarker
        ? searchFormData?.searchquery?.originAirport.originPortMarker
        : {},
      originLocationCountry: searchFormData?.searchquery?.originAirport
        .originLocationCountry
        ? searchFormData?.searchquery?.originAirport.originLocationCountry
        : "",
    },
    destinationAirport: {
      destinationAirport: searchFormData?.searchquery?.destinationAirport
        .destinationAirport
        ? searchFormData?.searchquery?.destinationAirport.destinationAirport
        : "",
      destinationLocation: searchFormData?.searchquery?.destinationAirport
        .destinationLocation
        ? searchFormData?.searchquery?.destinationAirport.destinationLocation
        : "",
      destinationPickup:
        searchFormData?.searchquery?.destinationAirport.destinationPickup ===
        "1"
          ? true
          : false,
      destinationLocationMarker: searchFormData?.searchquery?.destinationAirport
        .destinationLocationMarker
        ? searchFormData?.searchquery?.destinationAirport
            .destinationLocationMarker
        : {},
      destinationPortMarker: searchFormData?.searchquery?.destinationAirport
        .destinationPortMarker
        ? searchFormData?.searchquery?.destinationAirport.destinationPortMarker
        : {},
      destinationLocationCountry: searchFormData?.searchquery
        ?.destinationAirport.destinationLocationCountry
        ? searchFormData?.searchquery?.destinationAirport
            .destinationLocationCountry
        : "",
    },
    goodsDetails: {
      cargoreadydate: searchFormData?.searchquery?.goodsDetails.cargoreadydate
        ? searchFormData?.searchquery?.goodsDetails.cargoreadydate
        : dayjs().add(1, "day").format("DD/MM/YYYY"),
      commonditytype: {
        commonditytype: searchFormData?.searchquery?.goodsDetails.commonditytype
          .commonditytype
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              .commonditytype
          : "General Cargo",
        commonditytypesub: searchFormData?.searchquery?.goodsDetails
          .commonditytype.commonditytypesub
          ? searchFormData?.searchquery?.goodsDetails.commonditytype
              .commonditytypesub
          : "",

        generalcargo: {
          generalgoods: "All",
        },
        specialcargo,
      },
    },
    packagingDetails: {
      packagingDetailsBy: searchFormData?.searchquery?.packagingDetails
        .packagingDetailsBy
        ? searchFormData?.searchquery?.packagingDetails.packagingDetailsBy
        : "Packaging Type",
      shipmentTotal: {
        totalunits: searchFormData?.searchquery?.packagingDetails?.shipmentTotal
          ?.totalunits
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .totalunits
          : "",
        totalvolume: searchFormData?.searchquery?.packagingDetails
          ?.shipmentTotal?.totalvolume
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .totalvolume
          : "",
        unit: searchFormData?.searchquery?.packagingDetails?.shipmentTotal?.unit
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal.unit
          : "CBM",
        totalweight: searchFormData?.searchquery?.packagingDetails
          ?.shipmentTotal?.totalweight
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .totalweight
           : "",
        weightunit: searchFormData?.searchquery?.packagingDetails?.shipmentTotal
          ?.weightunit
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .weightunit
          : "KG",
        totalpackagetype: searchFormData?.searchquery?.packagingDetails
          ?.shipmentTotal?.totalpackagetype
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .totalpackagetype
          : "Box",
        handling: searchFormData?.searchquery?.packagingDetails?.shipmentTotal
          ?.handling
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal.handling
          : "Stackable",
        packinglistfile: "",
        weightless: searchFormData?.searchquery?.packagingDetails?.shipmentTotal
          ?.weightless
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .weightless
          : "",
        dimensionless: searchFormData?.searchquery?.packagingDetails
          ?.shipmentTotal?.dimensionless
          ? searchFormData?.searchquery?.packagingDetails.shipmentTotal
              .dimensionless
          : "",
      },
      packagingType: {
        packages: PackagesInit,
        totalunits: searchFormData?.searchquery?.packagingDetails?.packagingType
          ?.totalunits
          ? searchFormData?.searchquery?.packagingDetails.packagingType
              .totalunits
          : "",
        totalvolume: searchFormData?.searchquery?.packagingDetails
          ?.packagingType?.totalvolume
          ? searchFormData?.searchquery?.packagingDetails.packagingType
              .totalvolume
          : "",
        totalweight: searchFormData?.searchquery?.packagingDetails
          ?.packagingType?.totalweight
          ? searchFormData?.searchquery?.packagingDetails.packagingType
              .totalweight     // here to change the code to set value in the packagetype outer value in the form
          : "",
      },
    },
    paymentTerms: {
      paymentType: searchFormData?.searchquery?.paymentTerms.paymentType
        ? searchFormData?.searchquery?.paymentTerms.paymentType
        : "Prepaid",
      incoTerms: searchFormData?.searchquery?.paymentTerms.incoTerms
        ? searchFormData?.searchquery?.paymentTerms.incoTerms
        : "",
    },
  };
  const validationSchema = Yup.object().shape({
    originAirport: Yup.object().shape({
      originAirport: Yup.string().required("Origin Airport is required"),
    }),
    destinationAirport: Yup.object().shape({
      destinationAirport: Yup.string().required(
        "Destination Airport is required"
      ),
    }),
    goodsDetails: Yup.object().shape({
      commonditytype: Yup.object().shape({
        commonditytype: Yup.string().required("Goods Details is required"),
      }),
    }),
    packagingDetails: Yup.object().shape({
      packagingDetailsBy: Yup.string().required("Packing Details is required"),
    }),
    paymentTerms: Yup.object().shape({
      incoTerms: Yup.string().required("Payment Terms is required"),
    }),
  });
  const open = Boolean(anchorEl);
  function findflights(values) {
    dispatch(searchrates(JSON.stringify(values))).then((res) => {
      // console.log(res);
      navigate("/search-rates/search-rates-result");
    });
  }
  function openmodal(values, msg, type) {
    Modal.confirm({
      title: msg,
      okText: "Proceed",
      cancelButtonProps: {
        style: {
          color: "#FFAF00",
          borderColor: "#FFAF00",
        },
        
      },
      onOk() {
        if (type === "origin") {
          values.originAirport.originPickup = false;
          values.originAirport.originLocation = "";
          values.originAirport.originLocationMarker = {};
          values.originAirport.originPortMarker = {};
          values.originAirport.originLocationCountry = "";
        } else if (type === "destination") {
          values.destinationAirport.destinationPickup = false;
          values.destinationAirport.destinationLocation = "";
          values.destinationAirport.destinationLocationMarker = {};
          values.destinationAirport.destinationPortMarker = {};
          values.destinationAirport.destinationLocationCountry = "";
        } else if (type === "thirdcountry") {
          values.originAirport.originPickup = false;
          values.originAirport.originLocation = "";
          values.destinationAirport.destinationPickup = false;
          values.destinationAirport.destinationLocation = "";
        }
        console.log("value1", values);
        findflights(values);
      },
      onCancel() {},
      okButtonProps: {
        style: {
          color: "#ffffff",
          backgroundColor: "#FFAF00",
        },
      },
    });
  }
  const onSubmit = (values,setprops)=>{
    console.log(values,"klklk");
  findflights(values)
    // if(values.paymentTerms.incoTerms !== 'Third Country'){
    //     const importData = IncoTerms.imports.find(item => item.name === values.paymentTerms.incoTerms)
    //     const importDataOriginSerives = importData?.services?.originServices?.map(item => { return item?.name })
    //     const importDataDestiSerives = importData?.services?.destinationServices?.map(item => { return item?.name })
    //     const exportData = IncoTerms.exports.find(item => item.name === values.paymentTerms.incoTerms)
    //     const exportDataOriginSerives = exportData?.services?.originServices?.map(item => { return item?.name })
    //     const exportDataDestiSerives = exportData?.services?.destinationServices?.map(item => { return item?.name })

    //     if(importData && importDataOriginSerives?.includes('Origin Transportation')&& values.originAirport.originPickup === false ){   //
    //       // setprops.setFieldError('originAirport.originLocation','Origin Location and Origin Pickup are required')
    //       openmodal(values,'Origin Location and Origin Pickup are required','origin')  
    //       return false
    //     }
    //     //CASE
    //     if(importData && importDataDestiSerives?.includes('Destination Transportation')&& values.destinationAirport.destinationPickup === false ){//
    //       // setprops.setFieldError('destinationAirport.destinationLocation','Destination Location and Destination Dropoff are required')
    //       openmodal(values,'Destination Location and Destination Dropoff are required','destination') 
    //       return false
    //     }
    //     //CASE
    //     if(exportData && exportDataOriginSerives?.includes('Origin Transportation')&& values.originAirport.originPickup === false){ 
    //        console.log("export origin",exportData,exportDataOriginSerives,values.originAirport.originPickup); 
    //       // setprops.setFieldError('originAirport.originLocation','Origin Location and Origin Pickup are required')
    //       openmodal(values,'Origin Location and Origin Pickup are required','origin')  
    //       return false
    //     }
    //     if(exportData && exportDataDestiSerives?.includes('Destination Transportation') && values.destinationAirport.destinationPickup === false){
    //       console.log("export desti",exportData,exportDataDestiSerives);   
    //       // setprops.setFieldError('destinationAirport.destinationLocation','Destination Location and Destination Dropoff are required')
    //       openmodal(values,'Destination Location and Destination Dropoff are required','destination')  
    //       return false
    //     }
    //     if(importData && !importDataOriginSerives?.includes('Origin Transportation') && values.originAirport.originPickup === true){
    //         openmodal(values,'Origin Transportation not available','origin')
    //         return false
    //     }
    //     if(importData && !importDataDestiSerives?.includes('Destination Transportation') && values.destinationAirport.destinationPickup === true){
    //         openmodal(values,'Destination Transportation not available','destination')
    //         return false
    //     }
    //     if(exportData && !exportDataOriginSerives?.includes('Origin Transportation') && values.originAirport.originPickup === true){
    //         openmodal(values,'Origin Transportation not available','origin')
    //         return false
    //     }
    //     if(exportData && !exportDataDestiSerives?.includes('Destination Transportation') && values.destinationAirport.destinationPickup === true){
    //         openmodal(values,'Destination Transportation not available','destination')
    //         return false
    //     }
    //     findflights(values)
    // }else{
    //     if(values.originAirport.originPickup === true){
    //         openmodal(values,'Origin Transportation not available','thirdcountry')
    //         return false
    //     }
    //     if(values.destinationAirport.destinationPickup === true){
    //         openmodal(values,'Destination Transportation not available','thirdcountry')
    //         return false
    //     }
    //     findflights(values)
    // }
}
  // const onSubmit = (values, setprops) => {
  //   if (values.paymentTerms.incoTerms !== "Third Country") {
  //     console.log(values, values.paymentTerms.incoTerms);
  //     const importData = IncoTerms.imports.find(
  //       (item) => item.name === values.paymentTerms.incoTerms
  //     );
  //     const importDataOriginSerives = importData?.services?.originServices?.map(
  //       (item) => {
  //         return item?.name;
  //       }
  //     );
  //     const importDataDestiSerives =
  //       importData?.services?.destinationServices?.map((item) => {
  //         return item?.name;
  //       });
  //     const exportData = IncoTerms.exports.find(
  //       (item) => item.name === values.paymentTerms.incoTerms
  //     );
  //     const exportDataOriginSerives = exportData?.services?.originServices?.map(
  //       (item) => {
  //         return item?.name;
  //       }
  //     );
  //     const exportDataDestiSerives =
  //       exportData?.services?.destinationServices?.map((item) => {
  //         return item?.name;
  //       });
  //     // && values.originAirport.originPickup === false
  //     if (
  //       importData &&
  //       importDataOriginSerives?.includes("Origin Transportation")
  //     ) {
  //       console.log(importData, importDataOriginSerives);

  //       setprops.setFieldError(
  //         "originAirport.originLocation",
  //         "Origin Location and Origin Pickup are required"
  //       );
  //       return false;
  //     }
  //     if (
  //       importData &&
  //       importDataDestiSerives?.includes("Destination Transportation")
  //     ) {
  //       setprops.setFieldError(
  //         "destinationAirport.destinationLocation",
  //         "Destination Location and Destination Dropoff are required"
  //       );
  //       return false;
  //     }
  //     if(exportData && exportDataOriginSerives?.includes('Origin Transportation')){
  //         setprops.setFieldError('originAirport.originLocation','Origin Location and Origin Pickup are required')
  //         return false
  //     }
  //     if (
  //       exportData &&
  //       exportDataDestiSerives?.includes("Destination Transportation")
  //     ) {
  //       console.log(exportData, exportDataDestiSerives);

  //       setprops.setFieldError(
  //         "destinationAirport.destinationLocation",
  //         "Destination Location and Destination Dropoff are required"
  //       );
  //       return false;
  //     }
  //     if (
  //       importData &&
  //       !importDataOriginSerives?.includes("Origin Transportation") &&
  //       values.originAirport.originPickup === true
  //     ) {
  //       openmodal(values, "Origin Transportation not available", "origin");
  //       return false;
  //     }
  //     if (
  //       importData &&
  //       !importDataDestiSerives?.includes("Destination Transportation") &&
  //       values.destinationAirport.destinationPickup === true
  //     ) {
  //       openmodal(
  //         values,
  //         "Destination Transportation not available",
  //         "destination"
  //       );
  //       return false;
  //     }
  //     if (
  //       exportData &&
  //       !exportDataOriginSerives?.includes("Origin Transportation") &&
  //       values.originAirport.originPickup === true
  //     ) {
  //       openmodal(values, "Origin Transportation not available", "origin");
  //       return false;
  //     }
  //     if(exportData && !exportDataDestiSerives?.includes('Destination Transportation') 
  //    // && values.destinationAirport.destinationPickup === true
  //   )
  //   {
  //    console.log("values.paymentTerms.incoTerms !== Third Country",values)
  //         openmodal(values,'Destination Transportation not available','destination')
  //         return false
  //     }
  //     console.log("values", values);
  //     findflights(values);
  //   } else {
  //     if (values.originAirport.originPickup === true) {
  //       openmodal(
  //         values,
  //         "Origin Transportation not available",
  //         "thirdcountry"
  //       );
  //       return false;
  //     }
  //     if (values.destinationAirport.destinationPickup === true) {
  //       openmodal(
  //         values,
  //         "Destination Transportation not available",
  //         "thirdcountry"
  //       );
  //       return false;
  //     }
  //     console.log("value2", values);
  //     findflights(values);
  //   }
  // };
  useEffect(() => {
    if (allAirportsState?.allairports?.length > 0) {
      let optionData = [];
      allAirportsState?.allairports.map((item) => {
        return optionData.push({
          value: `${item.cityName} - (${item.code})`,
          label: `${item.cityName} - (${item.code})`,
        });
      });
      setAirports(optionData);
    } else {
      dispatch(getallairports());
    }
  }, [dispatch, allAirportsState]);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setWebOpen(false);
      } else {
        setWebOpen(true);
      }
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    handleResize();
  });
  console.log(initialValues);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="p-5  grid grid-cols-12 gap-4">
                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 col-span-3">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Origin Airport<span className="text-color-800">*</span>
                  </p>
                  <div
                    onClick={(e) => handleClick(e, formik, "OriginAirport")}
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                  >
                    {formik.values.originAirport.originAirport ? (
                      <span className="text-formlabel">
                        {formik.values.originAirport.originAirport}
                      </span>
                    ) : (
                      <span>Select Origin / Search by Port name or code</span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="originAirport.originAirport"
                    component={TextError}
                  />
                  <ErrorMessage
                    name="originAirport.originLocation"
                    component={TextError}
                  />
                </div>

                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 col-span-3">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Destination Airport<span className="text-red-800">*</span>
                  </p>
                  <div
                    onClick={(e) =>
                      handleClick(e, formik, "DestinationAirport")
                    }
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm rounded-md font-normal w-full px-3 py-3 flex items-center justify-between gap-0"
                  >
                    {formik.values.destinationAirport.destinationAirport ? (
                      <span className="text-formlabel">
                        {formik.values.destinationAirport.destinationAirport}
                      </span>
                    ) : (
                      <span>
                        Select Destination / Search by Port name or code
                      </span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="destinationAirport.destinationAirport"
                    component={TextError}
                  />
                  <ErrorMessage
                    name="destinationAirport.destinationLocation"
                    component={TextError}
                  />
                </div>
                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-6">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Goods Details
                  </p>
                  <div
                    onClick={(e) => handleClick(e, formik, "GoodDetails")}
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                  >
                    {formik.values.goodsDetails.commonditytype
                      .commonditytype ? (
                      <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                        <span className="bg-primary px-2.5 py-1 rounded-sm ">
                          {formik.values.goodsDetails.cargoreadydate}
                        </span>
                        <span className="bg-primary px-2.5 py-1 rounded-sm ">
                          {
                            formik.values.goodsDetails.commonditytype
                              .commonditytype
                          }
                        </span>
                        {formik.values.goodsDetails.commonditytype
                          .commonditytype === "General Cargo" ? (
                          // <span className="bg-primary px-2.5 py-1 rounded-sm ">
                          //   {
                          //     formik.values.goodsDetails.commonditytype
                          //       .generalcargo.generalgoods
                          //   }
                          // </span>
                          <span>
                            
                          </span>
                        ) : (
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            {
                              formik.values.goodsDetails.commonditytype
                                .commonditytypesub
                            }
                          </span>
                        )}
                      </div>
                    ) : (
                      <span>Add Goods Details</span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="goodsDetails.commonditytype.commonditytype"
                    component={TextError}
                  />
                </div>
                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-5">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Packaging Details
                  </p>
                  <div
                    onClick={(e) => handleClick(e, formik, "PackagingDetails")}
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                  >
                    {formik.values.packagingDetails.packagingDetailsBy ? (
                      formik.values.packagingDetails.packagingDetailsBy ===
                      "Packaging Type" ? (
                        <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Quantity:{" "}
                            {
                              formik.values.packagingDetails?.packagingType
                                ?.totalunits? formik.values.packagingDetails?.packagingType
                                ?.totalunits:0
                            }{" "}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Total Weight:{" "}
                            {
                              formik.values.packagingDetails?.packagingType
                                ?.totalweight?formik.values.packagingDetails?.packagingType
                                ?.totalweight :0
                            }{" "}{formik.values.packagingDetails.shipmentTotal.weightunit}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Total Volume:{" "}
                            {formik.values.packagingDetails?.packagingType
                              ?.totalvolume?formik.values.packagingDetails?.packagingType
                              ?.totalvolume:0
                             }{" "}{formik.values.packagingDetails.shipmentTotal.unit}
                          </span>
                        </div>
                      ) : (
                        <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Total Unit:{" "}
                            {
                              formik.values.packagingDetails.shipmentTotal
                                .totalunits
                            }
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Total Weight:{" "}
                            {
                              formik.values.packagingDetails.shipmentTotal
                                .totalweight
                            }
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Total Volume:{" "}
                            {
                              formik.values.packagingDetails.shipmentTotal
                                .totalvolume
                            }
                          </span>
                        </div>
                      )
                    ) : (
                      <span>Add Packaging Details</span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="packingDetails.packagingDetailsBy"
                    component={TextError}
                  />
                </div>
                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-5">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Payment Terms<span className="text-red-800">*</span>
                  </p>
                  <div
                    onClick={(e) => handleClick(e, formik, "PaymentTerms")}
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                  >
                    {formik.values.paymentTerms.incoTerms ||
                    formik.values.paymentTerms.paymentType ? (
                      <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-3">
                        <span className="bg-primary px-2.5 py-1 rounded-sm ">
                          {formik.values.paymentTerms.paymentType}
                        </span>
                        {formik.values.paymentTerms.incoTerms && (
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            {formik.values.paymentTerms.incoTerms}
                          </span>
                        )}
                      </div>
                    ) : (
                      <span>Add Payment Terms</span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="paymentTerms.incoTerms"
                    component={TextError}
                  />
                </div>
                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 col-span-2 relative flex items-end w-full">
                  <button
                    type="submit"
                    className="text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm px-4 py-3 text-center  w-full flex items-center justify-center gap-2 shadow-searchbox"
                  >
                    <SearchOutlined />
                    <span>Search</span>
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Popover
        className="w-full overflow-y-scroll"
        PaperProps={{
          style: {
            width:
              popForm === "PackagingDetails" && webopen === true
                ? "75%"
                : webopen === false
                ? "100%"
                : "50%",
                boxShadow: "10px 10px 10px 10px gray",
            borderRadius: "16px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {popForm === "OriginAirport" ? (
          <Suspense fallback={<Skeleton active />}>
            <OriginAirport
              formikData={formikData}
              setAnchorEl={setAnchorEl}
              airports={airports}
              allairports={allAirportsState?.allairports}
            />
          </Suspense>
        ) : popForm === "DestinationAirport" ? (
          <Suspense fallback={<Skeleton active />}>
            <DestinationAirport
              formikData={formikData}
              setAnchorEl={setAnchorEl}
              airports={airports}
              allairports={allAirportsState?.allairports}
            />
          </Suspense>
        ) : popForm === "GoodDetails" ? (
          <Suspense fallback={<Skeleton active />}>
            <GoodDetails formikData={formikData} setAnchorEl={setAnchorEl} />
          </Suspense>
        ) : popForm === "PackagingDetails" ? (
          <Suspense fallback={<Skeleton active />}>
            <PackagingDetails
              formikData={formikData}
              setAnchorEl={setAnchorEl}
            />
          </Suspense>
        ) : (
          popForm === "PaymentTerms" && (
            <Suspense fallback={<Skeleton active />}>
              <PaymentTerms
                formikData={formikData}
                setAnchorEl={setAnchorEl}
                allairports={allAirportsState?.allairports}
              />
            </Suspense>
          )
        )}
      </Popover>
    </>
  );
};

export default FlightRate;
