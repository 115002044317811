import React from 'react'
import logo from '../assets/images/main-logo.svg'
import arrowclose from '../assets/images/collapsed-arrows-left.svg'
import arrowopen from '../assets/images/collapsed-arrows-right.svg'
import routes from '../routes/routes'
import { NavLink, useLocation } from 'react-router-dom'
import { Tooltip } from 'antd'

const Sidebar = ({open,setOpen}) => {
    let path = useLocation();
    const pathArray = path.pathname.split('/')
    return (
        <aside className={` h-full fixed pb-10 rounded-xl transition-all duration-[0.5s] ease-in delay-[0s] ${open ? 'w-[260px]' : 'w-[80px]'}`} aria-label="Sidebar">
            <div className="overflow-y-auto h-full bg-white rounded-xl shadow-sidebarbox">
                <div className={`${open ? 'p-5' : 'p-0 my-5'}`}>
                    <div className={`flex items-center justify-between ${open ? 'flex-row' : 'flex-col'}`}>
                        <img src={logo} alt="SGATE" className={`cursor-pointer w-full my-2`}/>
                        <button type="button" className="flex text-white cursor-pointer my-2" onClick={()=> setOpen(!open)}>
                            <img src={open ? arrowclose : arrowopen} alt="Collapse Arrow" />
                        </button>
                    </div>
                </div>
                <hr className='border-[#F5F5F5]'/>
                <ul className="space-y-2 my-5">
                    {
                        routes.map((menu,index)=>{
                            return(
                                <li key={index}>
                                    <Tooltip title={menu.name} placement="right" trigger={!open ? "hover" : ''} color='#FFAF00'>
                                        <NavLink to={menu.path} className={`flex items-center py-3 px-5 text-sm font-semibold hover:bg-white relative bg-white ${pathArray[1] === menu.path ? 'after:content-[""] after:w-0 after:h-0 after:border-[15px] after:border-solid after:border-transparent after:top-0 after:bottom-0 after:margin-auto after:right-0 after:border-r-primary after:border-t-[25px] after:border-b-[22px] after:absolute text-primary shadow-avtivemenu': 'text-secondary'}`}>
                                            <img src={ pathArray[1] === menu.path ? menu.menuiconActive: menu.menuicon} alt={menu.name} />
                                            <span className={`${open ? 'block mx-4' : 'hidden'}`}>{menu.name}</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </aside>
    )
}

export default Sidebar