import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const countrycode = createAsyncThunk('countrycode', async ()=>{
    try{
        const url = 'phone_countrycode'
        const response = await httpinstance.get(url)
        console.log()
        return response
    }catch (err){
        return err.response
    }
})
export const notificationlist = createAsyncThunk('notificationlist', async (count)=>{
    try{
        const url = `notification/${count}`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const updatenotificationread = createAsyncThunk('updatenotificationread', async (id)=>{
    try{
        const url = `notification_status_update/${id}`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const updatenotificationreadall = createAsyncThunk('updatenotificationreadall', async ()=>{
    try{
        const url = `mark_as_all_read`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const updatenotificationneworold = createAsyncThunk('updatenotificationneworold', async ()=>{
    try{
        const url = `notification_read_status_update`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const getcurrency = createAsyncThunk('getcurrency', async ()=>{
    try{
        const url = `getcurrency`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    countryCode : {},
    updateUserHeaderTitle:'',
    notificationdata:{},
    bookingHistoryTabValue : 1,
    firebaseToken : '',
    notificationCount : '',
    allcurrency : []
}

const commonSlice = createSlice({
    name : 'commonslice',
    initialState,
    reducers: {
        updateUserHeaderTitle: (state, {payload})=>{
            state.updateUserHeaderTitle = payload
        },
        updatenotificationdata: (state, {payload})=>{
            state.notificationdata = payload
        },
        updatebookinghistorytabvalue: (state, {payload})=>{
            state.bookingHistoryTabValue = payload
        },
        updatefirebasetoken: (state, {payload})=>{
            state.firebaseToken = payload
        },
        updatenotificationcount: (state, {payload})=>{
            state.notificationCount = payload
        },
    },
    extraReducers:{
        [countrycode.fulfilled]:(state,{payload})=>{
            return {...state, countryCode:payload?.data}
        },
        [getcurrency.fulfilled]:(state,{payload})=>{
            return {...state, allcurrency:payload?.data?.nodeData}
        },
    }
})

export const { updateUserHeaderTitle } = commonSlice.actions
export const { updatenotificationdata } = commonSlice.actions
export const { updatebookinghistorytabvalue } = commonSlice.actions
export const { updatefirebasetoken } = commonSlice.actions
export const { updatenotificationcount } = commonSlice.actions
export const allcountrycode = (state) => state.commonapi.countryCode;
export const getupdateuserheadertitle = (state) => state.commonapi.updateUserHeaderTitle;
export const getnotificationdata = (state) => state.commonapi.notificationdata;
export const getbookinghistorytabvalue = (state) => state.commonapi.bookingHistoryTabValue;
export const getfirebasetoken = (state) => state.commonapi.firebaseToken;
export const getnotificationcount = (state) => state.commonapi.notificationCount;
export const getallcurrency = (state) => state.commonapi.allcurrency;
export default commonSlice.reducer