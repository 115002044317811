import React from 'react'
import BookedList from '../components/BookingHistory/BookedList'

const BookingHistory = () => {
    return (
        <div className="flex gap-5 w-full flex-col">
            <div className="sm:p-3 p-10 w-full rounded-2xl shadow-cardsection bg-white">
                <BookedList/>
            </div>
        </div>
    )
}
export default BookingHistory