import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const getallqueries = createAsyncThunk('getallqueries', async ()=>{
    try{
        const url = 'get_quotes_list'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const getsinglequery = createAsyncThunk('getsinglequery', async (values)=>{
    try{
        const url = 'getquery_details'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const getkam = createAsyncThunk('getkam', async ()=>{
    try{
        const url = 'get_kam'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const bookflight = createAsyncThunk('bookflight', async (values)=>{
    try{
        const url = 'book_for_payment'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const getorderid = createAsyncThunk('getorderid', async (values)=>{
    try{
        const url = 'create_order'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const verifytransaction = createAsyncThunk('verifytransaction', async (values)=>{
    try{
        const url = 'payment_verify_status'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const paymentfailed = createAsyncThunk('paymentfailed', async (values)=>{
    try{
        const url = 'payment_failure'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
    queryId : ''
}

const allQueriesSlice = createSlice({
    name : 'queriesslice',
    initialState,
    reducers:{
        savequeryid:(state,{payload})=>{
            state.queryId = payload
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(getallqueries.pending,(state)=>{
            return {...state, loading:true}
        })
        builder.addCase(getallqueries.fulfilled,(state,{payload})=>{
            return {...state,loading:false, data:payload}
        })
    }
})
export const { savequeryid } = allQueriesSlice.actions
export const queriessate = state => state.queriessate
export const getqueryId = state => state.queriessate.queryId
export default allQueriesSlice.reducer