import React from 'react'
import { Select } from 'antd';
import styled from 'styled-components';

function SelectSearchNo(props) {
    const onChange = (value) => {
        // console.log(`selected ${value}`);
        if(props.name){
            props.setFilter((prevState)=> {
                return {
                    ...prevState,
                    [props.name]: value
                }
            })
        }
      };
      const onSearch = (value) => {
        console.log('search:', value);
      };
  return (
    <div style={{ borderRadius: '12px'}}>
      <ItemSelect
        showSearch
        placeholder={props.placeholder? props.placeholder : "Select"}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        value={props?.value}
        style={{
            width: props.width? props.width : 200,
        }}
        filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
            {
            value: 'jack',
            label: 'Jack',
            },
            {
            value: 'lucy',
            label: 'Lucy',
            },
            {
            value: 'tom',
            label: 'Tom',
            },
        ]}
        />
    </div>
  )
}

export default SelectSearchNo

const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border: none !important;
        height: 36px !important;
        padding: 3px 11px !important;
        background-color: #F2F7FA !important;
    }
`