import {createSlice } from '@reduxjs/toolkit'
const initialState={
   
    selectedOptions:''
}

const classDGslice=createSlice({
    name: 'classDescription',
    initialState,
    reducers: {
        set_selectedOption(state,action){
            console.log("selected option",action.payload);
            state.selectedOptions=action.payload
            
        }
        
    }
})
export const {set_selectedOption} = classDGslice.actions;
export default classDGslice.reducer;