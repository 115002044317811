import React from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import headermenus from '../routes/headermenus';
import { logout } from '../store/Authentication/AuthSlice';

const HeaderMenus = ({hide}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const logOut = () => {
        dispatch(logout())
        .then((res) => {
            navigate("/auth/login");
        })
        .catch((err) => {
            console.error(err);
        });
    };
    return(
        <>
            <ul className="py-3">
                {
                    headermenus.map((menu,index)=>{
                        return menu.path !== 'logout' ?
                            <li key={index}>
                                <NavLink to={menu.path} className='flex items-center py-4 px-5 text-sm font-semibold hover:bg-[#F7F5FF] relative bg-white text-secondary' onClick={hide}>
                                    <img src={menu.menuicon} alt={menu.name} />
                                    <span className='block mx-4'>{menu.name}</span>
                                </NavLink>
                            </li>
                        :
                            <li key={index} onClick={logOut}>
                                <div  className='cursor-pointer flex items-center py-4 px-5 text-sm font-semibold hover:bg-[#F7F5FF] relative bg-white text-secondary'>
                                    <img src={menu.menuicon} alt={menu.name} />
                                    <span className='block mx-4'>{menu.name}</span>
                                </div>
                            </li>
                    })
                }
            </ul>
        </>
    )
}

export default HeaderMenus