// import firebase from 'firebase/compat/app';
// import 'firebase/compat/messaging';
// const firebaseConfig = {
//   apiKey: "AIzaSyCrVICml7x771dCZGDuXd_NV-bgigaj1ZY",
//   authDomain: "magic-woman-b1fb0.firebaseapp.com",
//   projectId: "magic-woman-b1fb0",
//   storageBucket: "magic-woman-b1fb0.appspot.com",
//   messagingSenderId: "547844608918",
//   appId: "1:547844608918:web:e75fa216bdd8b3535203b7",
//   measurementId: "G-XHFHN9Q66D"
// };

// firebase.initializeApp(firebaseConfig);
// export default firebase;
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDXFZS-fr_SKTOR-hYXR3CKYx4j0vl8uB4",
  authDomain: "fir-gate.firebaseapp.com",
  projectId: "fir-gate",
  storageBucket: "fir-gate.appspot.com",
  messagingSenderId: "865356886800",
  appId: "1:865356886800:web:1f292755cfdbe60f862b95",
  measurementId: "G-FB5CGWCL29"
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);
export default messaging