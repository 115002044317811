import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile } from "../../axios/api"

export const getallairports = createAsyncThunk('getallairports', async ()=>{
    try{
        const url = 'airports'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const getallshipments = createAsyncThunk('getallshipments', async ()=>{
    try{
        const url = 'list_ocean_port'
        const response = await httpinstance.get(url)
        console.log(response);
        return response
    }catch (err){
        return err.response
    }
})
const exponentialToDecimal = exponential => {
    let decimal = exponential.toString().toLowerCase();
    if (decimal.includes('e+')) {
      const exponentialSplitted = decimal.split('e+');
      let postfix = '';
      for (
        let i = 0; i <
        +exponentialSplitted[1] -
        (exponentialSplitted[0].includes('.') ? exponentialSplitted[0].split('.')[1].length : 0); i++
      ) {
        postfix += '0';
      }
      const addCommas = text => {
        let j = 3;
        let textLength = text.length;
        while (j < textLength) {
          text = `${text.slice(0, textLength - j)},${text.slice(textLength - j, textLength)}`;
          textLength++;
          j += 3 + 1;
        }
        return text;
      };
      decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
    }
    if (decimal.toLowerCase().includes('e-')) {
      const exponentialSplitted = decimal.split('e-');
      let prefix = '0.';
      for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
        prefix += '0';
      }
      decimal = prefix + exponentialSplitted[0].replace('.', '');
    }
    return decimal;
  };
export const searchrates = createAsyncThunk('searchrates', async (values)=>{
    try{
         
        console.log(values);
        const url = 'search_cargo_result'
        const response = await httpinstance.post(url,values)
         console.log("search_cargo_result response:", response);
        //  if(response.data.nodeData.searchquery.cargoType === "Air Cargo"){
        //     let patotalvol=response.data.nodeData.searchquery.packagingDetails.packagingType.totalvolume
        //     let shtotalvol=response.data.nodeData.searchquery.packagingDetails.shipmentTotal.totalvolume
        //     console.log(patotalvol,shtotalvol);
        //     let convpatotalvol=exponentialToDecimal(patotalvol);
        //     let convshtotalvol=exponentialToDecimal(shtotalvol);
        //     console.log(convpatotalvol,convshtotalvol);
        //      response.data.nodeData.searchquery.packagingDetails.packagingType.totalvolume=convpatotalvol
        //      response.data.nodeData.searchquery.packagingDetails.shipmentTotal.totalvolume=convshtotalvol
        //      console.log("1")
        //  }else if(response.data.nodeData.searchquery.cargoType === "Ocean Cargo"){
        //     let oceantotalvolume=response.data.nodeData.searchquery.containerDetails.packagingDetails.totalVolume
        //     console.log(oceantotalvolume)
        //     let convoceantotalvolume=exponentialToDecimal(oceantotalvolume)
        //     response.data.nodeData.searchquery.containerDetails.packagingDetails.totalVolume=convoceantotalvolume
        //       console.log(convoceantotalvolume,"2")
        //  }
        
        //   if(response.data.nodeData.totalVolume&&response.data.nodeData.searchquery.packagingDetails.packagingType.totalvolume && response.data.nodeData.searchquery.packagingDetails.shipmentTotal.totalvolume){
        //  const totalvol=response.data.nodeData.totalVolume
        // const resultvol=exponentialToDecimal(totalvol)
        //  response.data.nodeData.totalVolume=resultvol
        //  const pvol=response.data.nodeData.searchquery.packagingDetails.packagingType.totalvolume
        //  const svol=response.data.nodeData.searchquery.packagingDetails.shipmentTotal.totalvolume

        //  const sresultvol=exponentialToDecimal(svol);
        //  response.data.nodeData.searchquery.packagingDetails.shipmentTotal.totalvolume=sresultvol
        //  const presultvol=exponentialToDecimal(pvol);
        //  response.data.nodeData.searchquery.packagingDetails.packagingType.totalvolume=presultvol
        // console.log("search_cargo_result slice",response ,response.data.nodeData.totalVolume,response.data.nodeData.searchquery.packagingDetails.packagingType.totalvolume);
        //   }
        return response
    }catch (err){
        return err.response
    }
})

export const searchratefileup = createAsyncThunk('searchratefileup', async (values)=>{
    try{
        const url = 'fileupload'
        const response = await httpinstancefile.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const checkblackedlist = createAsyncThunk('checkblackedlist', async (values)=>{
    try{
        const url = 'checkblacklist'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
    allairports:{},
    allshipports:{}
}

const searchRatesSlice = createSlice({
    name : 'searchrates',
    initialState,
    reducers:{
        clearstate: (state)=>{
            state.data = {}
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(searchrates.pending,(state)=>{
            return {...state, loading:true}
        })
        builder.addCase(searchrates.fulfilled,(state,{payload})=>{
            console.log("hi",payload)
            return {...state,loading:false,data:payload}
        })
        builder.addCase(getallairports.fulfilled,(state,{payload})=>{
            return {...state,loading:false, allairports:payload?.data?.data}
        })
        builder.addCase(getallshipments.fulfilled,(state,{payload})=>{
            console.log("hi",payload)
            return {...state,loading:false, allshipports:payload?.data?.data}
        })
    }
})
export const { clearstate } = searchRatesSlice.actions
export const searchratestate = state => state.searchrates
export default searchRatesSlice.reducer