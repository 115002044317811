import React, { useEffect, useState } from 'react'
import { Avatar, Badge, Drawer, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getnotificationcount, notificationlist, updatenotificationcount } from '../../../store/Common/CommonSlice';
import { BellOutlined } from '@ant-design/icons';
import NotificationsList from './NotificationsList';

const NotificationBell = () => {
    const [notificationData, setNotificationData] = useState();
    const [open, setOpen] = useState(false);
    const notificationCount = useSelector(getnotificationcount);
    const dispatch = useDispatch();
    const count = 10
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(()=>{
        dispatch(notificationlist(count))
        .then((res)=>{
            if(res?.payload?.data?.statusCode === 200){
                setNotificationData(res?.payload?.data)
                dispatch(updatenotificationcount(res?.payload?.data?.newCount))
            }
        })
        .catch(err=>{
            console.error(err);
        })
    },[open])
    return (
        <>
            <Badge count={notificationCount} className="text-primary">
                {/* <Popover showArrow={false}  overlayInnerStyle={{width:'100%', padding:0}} placement="bottomRight" content={<NotificationsList notificationData={notificationData} count={count} setOpen={setOpen} open={open}/>} trigger="click" open={open} onOpenChange={handleOpenChange}> */}
                    <Avatar onClick={showDrawer} shape="circle" size='large' style={{display:'flex',alignItems:'center',justifyContent:'center'}} icon={<BellOutlined />} className="bg-primary hover:bg-secondary cursor-pointer"/>
                {/* </Popover> */}
            </Badge>
            <Drawer placement="right" onClose={onClose} open={open} size='default'>
                <NotificationsList notificationData={notificationData} count={count} setOpen={setOpen} open={open}/>
            </Drawer>
        </>
    )
}

export default React.memo(NotificationBell)