import React from 'react'
import Dashboard from '../components/Dashboard/Dashboard'

const Home = () => {
  return (
    <div>
        <Dashboard/>
    </div>
  )
}

export default Home

// import React, { Suspense, lazy } from 'react'
// import { Skeleton } from 'antd'
// const ShipmentDocuments = lazy(()=> import('../components/DashboardNew/ShipmentDocuments/ShipmentDocuments')) ;
// const Invoices = lazy(()=> import('../components/DashboardNew/Invoices/Invoices')) ;
// const RateOverview = lazy(()=> import('../components/DashboardNew/RateOverview/RateOverview')) ;
// const CompleteOverview = lazy(()=> import('../components/DashboardNew/CompleteOverview/CompleteOverview')) ;
// const ShipmentOverviewOcean = lazy(()=> import('../components/DashboardNew/ShipmentOverviewOcean/ShipmentOverviewOcean')) ;
// const ShipmentOverviewAir = lazy(()=> import('../components/DashboardNew/ShipmentOverviewAir/ShipmentOverviewAir')) ;
// const WelcomeCard = lazy(()=> import('../components/DashboardNew/WelcomeCard/WelcomeCard'));

// const Dashboard = () => {
//     return (
//         <div className="w-full flex flex-col gap-y-16" id='dashboardcomp'>
//             <Suspense fallback={<Skeleton active/>}>
//                 <WelcomeCard/>
//             </Suspense>
//             <Suspense fallback={<Skeleton active/>}>
//                 <ShipmentOverviewOcean/>
//             </Suspense>
//             <Suspense fallback={<Skeleton active/>}>
//                 <ShipmentOverviewAir/>
//             </Suspense>
//             <Suspense fallback={<Skeleton active/>}>
//                 <CompleteOverview/>
//             </Suspense>
//             <div className="grid grid-cols-4 gap-8">
//                 <div class="col-span-3 lg:col-span-2 md:col-span-4 sm:col-span-4">
//                     <Suspense fallback={<Skeleton active/>}>
//                         <RateOverview/>
//                     </Suspense>
//                 </div>
//                 <div class="col-span-1 lg:col-span-2 md:col-span-4 sm:col-span-4">
//                     <Suspense fallback={<Skeleton active/>}>
//                         <Invoices/>
//                     </Suspense>
//                 </div>
//             </div>
//             <Suspense fallback={<Skeleton active/>}>
//                 <ShipmentDocuments/>
//             </Suspense>
//         </div>
//     )
// }

// export default Dashboard