import axios from "axios";


export const httpinstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {"Content-Type": "application/json",}
})
httpinstance.interceptors.request.use(async (request)=>{
    const token = localStorage.getItem('accessToken');
    if(token) request.headers.Authorization = `Bearer ${token}` 
    return request
})

httpinstance.interceptors.response.use(async (responce)=>{
    return responce
}, async (error)=>{
    const originalConfig = error.config;
    const user = JSON.parse(localStorage.getItem('user'));

    if (originalConfig.url !== "/auth/login" && error.response) {
        // Access Token was expired
        switch(error.response.data.statusCode){
            case 401:
                try {
                    const rs = await httpinstance.post("/auth/refresh_token", {"userId" : user.id});
                    let accessToken = rs?.data?.data?.original?.access_token;
                    localStorage.setItem("accessToken", accessToken);
                    originalConfig._retry = true;
                    return httpinstance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            case 402:
                localStorage.removeItem("accessToken")
                localStorage.removeItem("user")
                window.location.replace(`${window.location.origin}/auth/login`);
                break;
            default :
                return Promise.reject(error)
        }
    }
    return Promise.reject(error)
})

export const httpinstancefile = axios.create({
    baseURL:process.env.REACT_APP_API_BASE_URL,
    headers: {"Content-Type": "multipart/form-data",}
})

httpinstancefile.interceptors.request.use(async (request)=>{
    const token = localStorage.getItem('accessToken');
    if(token) request.headers.Authorization = `Bearer ${token}`
    return request
})

httpinstancefile.interceptors.response.use(async (responce)=>{
    return responce
}, async (error)=>{
    const originalConfig = error.config;
    const user = JSON.parse(localStorage.getItem('user'));

    if (originalConfig.url !== "/auth/login" && error.response) {
        // Access Token was expired
        switch(error.response.data.statusCode){
            case 401:
                try {
                    const rs = await httpinstance.post("/auth/refresh_token", {"userId" : user.id});
                    let accessToken = rs?.data?.data?.original?.access_token;
                    localStorage.setItem("accessToken", accessToken);
                    originalConfig._retry = true;
                    return httpinstance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            case 402:
                localStorage.removeItem("accessToken")
                localStorage.removeItem("user")
                window.location.replace(`${window.location.origin}/auth/login`);
                break;
            default :
                return Promise.reject(error)
        }
    }
    return Promise.reject(error)
})
