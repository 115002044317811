import React, { useEffect, useState } from 'react'
import shipicon from '../assets/images/ship-icon.svg'
import flighticon from '../assets/images/flight-icon.svg'
import activeshipicon from '../assets/images/active-ship-icon.svg'
import activeflighticon from '../assets/images/active-flight-icon.svg'
import FlightRates from '../components/SearchRates/FlightRates'
import worldmap from '../assets/images/world-map.gif'
import { useDispatch, useSelector } from 'react-redux'
import { clearstate, searchratestate } from '../store/SearchRates/SearchRatesSlice'
import { notification, Spin } from 'antd'
import { kycdocstatus, verificationstatus } from '../store/User/KycSlice'
import { NavLink } from 'react-router-dom'
import ShipmentRates from '../components/SearchRates/ShipmentRates'

const SearchRates = () => {
    const [searchBy, setSearchBy] = useState()
    const verificationStatus = useSelector(verificationstatus)
    const [api, contextHolder] = notification.useNotification();
    const key = `open${Date.now()}`;
    const dispatch = useDispatch();
    const allSarchState = useSelector(searchratestate)

    const openNotification = (val,key) => {
        if(val){
            api.destroy()
        }else{
            api.open({
            message: <NavLink to='/user/kyc'>Note: Please complete your KYC verification for a hassle-free booking!</NavLink>,
            duration: 0,
            placement:'top',
            key,
            style: {
                width: 600,
            },
            });
        }
    };
    useEffect(()=>{
        if(verificationStatus === 'Pending_Upload'){
            openNotification(false,key)
        }else{
            openNotification(true,key)
        }
    },[verificationStatus])
    useEffect(()=>{
        dispatch(kycdocstatus())
    },[])
    useEffect(()=>{
        if(allSarchState?.data?.data?.nodeData){
            setSearchBy(allSarchState?.data?.data?.nodeData?.searchquery?.cargoType)
            console.log("51",allSarchState?.data?.data?.nodeData?.searchquery?.cargoType)
        }else if(!searchBy){
            setSearchBy('Air Cargo')
        }
    },[allSarchState?.data,searchBy])
    
    return (
        <>  
            {
                <Spin delay={1000}  spinning={allSarchState.loading} tip="Searching..." size="large" className='max-h-full text-primary text-lg font-bold text-center'>
                    <div className="flex gap-5 w-full flex-col">
                        <div className="w-full rounded-2xl shadow-cardsection bg-white">
                            <div className='flex p-5 justify-between items-center flex-wrap gap-5'>
                                <p className="flex font-bold text-lg leading-5">Book Your Cargo</p>
                                <div className="flex gap-5">
                                    <button type='button' onClick={()=>{setSearchBy('Air Cargo'); if(Object.keys(allSarchState?.data).length > 0) {dispatch(clearstate())}}} className={`rounded-lg border-2 p-2 w-[50px] h-[50px]  bg-center bg-no-repeat flex items-center justify-center hover:border-primary ${searchBy === "Air Cargo" ? 'border-primary' : 'bg-[url("assets/images/flight-icon.svg")] hover:bg-[url("assets/images/active-flight-icon.svg")]'}`}>
                                        {
                                            searchBy === 'Air Cargo' &&
                                            <img src={activeflighticon} alt="Active Flight" />
                                        }
                                    </button>
                                    <button type='button' onClick={()=>{setSearchBy('Ocean Cargo'); if(Object.keys(allSarchState?.data).length > 0) {dispatch(clearstate())}  }} className={`rounded-lg border-2 p-2 w-[50px] h-[50px]  bg-center bg-no-repeat flex items-center justify-center hover:border-primary ${searchBy === "Ocean Cargo" ? 'border-primary' : 'bg-[url("assets/images/ship-icon.svg")] hover:bg-[url("assets/images/active-ship-icon.svg")]'}`}>
                                        {
                                            searchBy === 'Ocean Cargo' &&
                                            <img src={activeshipicon} alt="Active Ship" />
                                        }
                                    </button>
                                </div>
                            </div>
                            <hr className='border-[#F5F5F5]'/>
                            {
                                searchBy === 'Ocean Cargo' ? <ShipmentRates searchBy={searchBy} searchFormData={allSarchState?.data?.data?.nodeData}/> : searchBy === 'Air Cargo' ? <FlightRates searchBy={searchBy} searchFormData={allSarchState?.data?.data?.nodeData}/> : ''
                            }
                            
                        </div>
                        <div className="w-full rounded-2xl shadow-cardsection bg-white p-5 flex items-center justify-center">
                            <img src={worldmap} alt="World Map"  />
                        </div>
                    </div>
                </Spin>
            }
            {contextHolder}
        </>
    )
}
export default SearchRates