import { RightOutlined } from '@ant-design/icons';
import { List, Skeleton } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { notificationlist, updatenotificationneworold, updatenotificationread, updatenotificationreadall } from '../../../store/Common/CommonSlice';
import { Link } from 'react-router-dom';
import { savequeryid } from '../../../store/Queries/QueriesSlice';

const NotificationsList = ({notificationData,count,setOpen,open}) => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [newCount, setNewCount] = useState(count);
    const dispatch = useDispatch();

    const onLoadMore = () => {
        setLoading(true);
        let countvalue = count + newCount;
        setNewCount(countvalue)
        dispatch(notificationlist(countvalue))
        .then((res)=>{
            if(res?.payload?.data?.statusCode === 200){
                const newData = res?.payload?.data?.data;
                setList(newData);
                setLoading(false);
            }
        })
        .catch(err=>{
            console.error(err);
        })
    };
    const readAll = () =>{
        setOpen(false);
        dispatch(updatenotificationreadall())
    }
    const handleClose = (id,queryId) => {
        if(id){
            dispatch(updatenotificationread(id))
        }
        if(queryId){
            dispatch(savequeryid(queryId))
        }
        setOpen(false);
    };
    const loadMore = <>
                        <hr className='border-[#F5F5F5] my-2'/>
                        <div className="flex p-5 gap-5 items-center justify-center flex-wrap">
                            {
                                (newCount < notificationData?.notificationCount) &&
                                    <button type="button" onClick={onLoadMore} className="text-white bg-primary hover:bg-secondary font-normal leading-4 rounded-lg text-sm p-2 text-center  flex items-center justify-center gap-2 shadow-searchbox">
                                        <span>Load More</span>
                                    </button>
                            }
                            {
                                list.length > 0 &&
                                <button type="button" onClick={readAll} className="text-white bg-primary hover:bg-secondary font-normal leading-4 rounded-lg text-sm p-2 text-center  flex items-center justify-center gap-2 shadow-searchbox">
                                    <span>Mark all as read</span>
                                </button>
                            }
                        </div>
                    </>
    useEffect(()=>{
        if(open){
            setLoading(true);
            dispatch(notificationlist(count))
            .then((res)=>{
                setLoading(false);
                if(res?.payload?.data?.statusCode === 200){
                    setList(res?.payload?.data?.data)
                    dispatch(updatenotificationneworold())
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
    },[open])
    return (
        <List className={loading ? 'p-5 my-5' : 'w-full'}
            itemLayout="horizontal"
            dataSource={list}
            loadMore={loadMore}
            renderItem={(item, index) => (
                <Skeleton active loading={loading}>
                    <Link to={item.notification_type === "Quote" ? '/queries/queries-details' : (item.notification_type === "Payment" && item.carrier_type === 'Air Cargo') ? '/booking-history/air-cargo-booking-details': (item.notification_type === "Payment" && item.carrier_type === 'Ocean Cargo') ? '/booking-history/ocean-cargo-booking-details' : item.notification_type === "KYC" ? '/user/kyc' : item.notification_type === "Credit" ? '/user/my-credits' :''} onClick={()=>handleClose(item.id,item.queryId)}>
                        <List.Item className={`hover:bg-[#F7F5FF] cursor-pointer flwx gap-2 break-word ${item.reading_status === 'read' ? 'bg-white' : 'bg-bgbox' }`}>
                            <List.Item.Meta
                            title={item.message}
                            />
                            <RightOutlined />
                        </List.Item> 
                    </Link>
                </Skeleton>
            )}
        />
    )
}

export default React.memo(NotificationsList)