import React, { useEffect, useState } from 'react'

const HeaderUserName = ({user_name}) => {
    const [usernameltr, setUserNameLtr] = useState();
    useEffect(()=>{
        function getFirstChar(str) {
        const firstChars = str
            ?.split(" ")
            ?.map((word) => word[0])
            ?.join("");
        return firstChars;
        }
        const userNameltr = getFirstChar(user_name?.full_name);
        userNameltr && setUserNameLtr(userNameltr);
    },[user_name])
    return (
        <div className="h-10 w-10 rounded-full avatar flex justify-center items-center text-white text-lg font-normal bg-primary">
            {usernameltr?.toUpperCase()}
        </div>
    )
}

export default React.memo(HeaderUserName)