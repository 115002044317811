import React,{Suspense, lazy, useEffect, useState} from 'react'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
//import Dashboard from '../pages/Dashboard'
import Header from './Header'
import MainContent from './MainContent'
import Sidebar from './Sidebar'
import { Skeleton } from 'antd'
import MyCredits from '../components/User/MyCredits'
import LearnCreditLimit from '../components/User/LearnCreditLimit'
import { useDispatch } from 'react-redux'
import { logout } from '../store/Authentication/AuthSlice'

const Dashboard = lazy(()=>import('../pages/Dashboard'))
const AirCargoDetails = lazy(()=> import('../components/BookingHistory/AirCargoDetails'))
const OceanCargoDetails = lazy(()=> import('../components/BookingHistory/OceanCargoDetails')) 
const SearchRates = lazy(()=> import('../pages/SearchRates'))
const SearchRatesResults = lazy(()=> import('../components/SearchRates/SearchRatesResults'))
const BookingHistory = lazy(()=> import('../pages/BookingHistory'))
const Queries = lazy(()=> import('../pages/Queries'))
const QueriesDetails = lazy(()=> import('../components/Queries/QueriesDetails'))
const MobHeader = lazy(()=> import('./MobHeader'))
const Profile = lazy(()=> import('../components/User/Profile'))
const KYC = lazy(()=> import('../components/User/KYC'))
const HelpAndSupport = lazy(()=> import('../components/User/HelpAndSupport'))
const Settings = lazy(()=> import('../components/User/Settings'))
const TermsAndConditions = lazy(()=> import('../components/User/TermsAndConditions'))
const UserPageLayout = lazy(()=> import('./UserPageLayout'))

const PageLayout = () => {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    useEffect(()=>{
        function handleResize() {
            if(window.innerWidth < 1440){
                setOpen(false)
            }else{
                setOpen(true)
            }
        }
        window.addEventListener('resize', handleResize)
        window.addEventListener('load', handleResize)
    })
    useEffect(()=>{
        let user = localStorage.getItem('user')
        if(user) user = JSON.parse(user)
        if(user?.userroles !== "customer"){
            dispatch(logout())
            .then((res) => {
                navigate("/auth/login");
            })
            .catch((err) => {
                console.error(err);
            });
        }
    },[])
    return (
        <div className="p-5 sm:p-0 relative bg-[#F8F9FA]">
            <div className="flex w-full h-full">
                <div className="block sm:hidden">
                    <Sidebar open={open} setOpen={setOpen}/>
                </div>
                <div className={`overflow-hidden gap-6 relative flex flex-col pl-10 pr-5 w-full transition-all duration-[0.5s] ease-in delay-[0s] sm:ml-0 sm:px-0 ${open ? 'ml-[260px]': 'ml-[90px]'}`}>
                    <div className="sm:hidden">
                        <Header/>
                    </div>
                    <div className="hidden sm:block">
                        <Suspense fallback={<Skeleton active/>}>
                            <MobHeader/>
                        </Suspense>
                    </div>
                    <MainContent>
                        <Routes>
                            {/* <Route exact path='/' element={<Suspense fallback={<Skeleton active/>}><Dashboard/></Suspense>}/> */}
                            <Route exact path='/' element={<Navigate to="/search-rates" replace />}/>
                            <Route exact path='search-rates' element={<Suspense fallback={<Skeleton active/>}><SearchRates/></Suspense>}/>
                            <Route exact path='search-rates/search-rates-result' element={<Suspense fallback={<Skeleton active/>}><SearchRatesResults/></Suspense>}/>
                            <Route exact path='booking-history' element={<Suspense fallback={<Skeleton active/>}><BookingHistory/></Suspense>}/>
                            <Route exact path='booking-history/air-cargo-booking-details' element={<Suspense fallback={<Skeleton active/>}><AirCargoDetails/></Suspense>}/>
                            <Route exact path='booking-history/ocean-cargo-booking-details' element={<Suspense fallback={<Skeleton active/>}><OceanCargoDetails/></Suspense>}/>
                            <Route exact path='queries' element={<Suspense fallback={<Skeleton active/>}><Queries/></Suspense>}/>
                            <Route exact path='queries/queries-details' element={<Suspense fallback={<Skeleton active/>}><QueriesDetails/></Suspense>}/>
                            <Route exact path='user/*' element={<Suspense fallback={<Skeleton active/>}><UserPageLayout/></Suspense>}>
                                <Route exact path='profile' element={<Suspense fallback={<Skeleton active/>}><Profile/></Suspense>}/>
                                <Route exact path='kyc' element={<Suspense fallback={<Skeleton active/>}><KYC/></Suspense>}/>
                                <Route exact path='help-and-support' element={<Suspense fallback={<Skeleton active/>}><HelpAndSupport/></Suspense>}/>
                                <Route exact path='settings' element={<Suspense fallback={<Skeleton active/>}><Settings/></Suspense>}/>
                                <Route exact path='terms-and-conditions' element={<Suspense fallback={<Skeleton active/>}><TermsAndConditions/></Suspense>}/>
                                <Route exact path='my-credits' element={<Suspense fallback={<Skeleton active/>}><MyCredits/></Suspense>}/>
                                <Route exact path='my-credits/learn-credit-limit' element={<Suspense fallback={<Skeleton active/>}><LearnCreditLimit/></Suspense>}/>
                            </Route>
                            <Route path="*" element={<Navigate to="/auth/login" replace />} />

                        </Routes>
                        <Outlet/>
                    </MainContent>
                </div>
            </div>
        </div>
    )
}

export default PageLayout