import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const offlinePaymentDetails = createAsyncThunk('offlinePaymentDetails', async (values)=>{
    try{
        const url = `add_offline_payment`
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const offlinePaymentDetailsList = createAsyncThunk('offlinePaymentDetailsList', async (values)=>{
    try{
        const url = `list_offline_payment`
        const response = await httpinstance.get(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    allbookingdata : []
}

const offlinePayment = createSlice({
    name : 'offlinepayment',
    initialState,
    reducers: {
        // updateUserHeaderTitle: (state, {payload})=>{
        //     state.updateUserHeaderTitle = payload
        // },
        // updatenotificationdata: (state, {payload})=>{
        //     state.notificationdata = payload
        // }
    },
    extraReducers:{
        [offlinePaymentDetailsList.fulfilled]:(state,{payload})=>{
            return {...state, allbookingdata:payload?.data}
        },
    }
})

// export const { updateUserHeaderTitle } = offlinePayment.actions
// export const { updatenotificationdata } = offlinePayment.actions
export const getallbookingdata = (state) => state.offlinepayment.allbookingdata;
export default offlinePayment.reducer