import { DownOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import React, { useState } from 'react'
import HeaderMenus from '../../../utils/HeaderMenus'

const DropDownButton = ({user_name}) => {
    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const hide = () => {
        setOpen(false);
    };
    return (
        <Popover showArrow={false}  overlayInnerStyle={{width:'100%', padding:0}} placement="bottomRight" content={<HeaderMenus hide={hide}/>} trigger="click" open={open} onOpenChange={handleOpenChange}>
            <button type='button' className="leading-4 font-bold text-sm text-secondary">
                <div className="flex gap-2 items-center">
                    <div className="sm:hidden">Hello! {user_name?.full_name.toUpperCase()}</div>
                    <DownOutlined />
                </div>
            </button>
        </Popover>

    )
}

export default React.memo(DropDownButton)