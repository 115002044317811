import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalUnitsVal: 0,
  totalWeightVal: 0,
  ftotalcbm: 0
};

const sharedStateSlice = createSlice({
  name: 'packaging',
  initialState,
  reducers: {
    set_Total_UnitsVal(state, action) {
      console.log("hi",action.payload)
      state.totalUnitsVal = action.payload;
    },
    set_Total_WeightVal(state, action) {
      state.totalWeightVal = action.payload;
      console.log("hi1",action.payload)
    },
    set_Total_CBM(state, action) {
      state.ftotalcbm = action.payload;
      console.log("hi2",action.payload)
    },
    set_Shipment_Total(state, action){
      console.log("hi4",action.payload)
    
      
      state.totalUnitsVal = action.payload.totalUnitsVal;
      
      state.totalWeightVal = action.payload.totalWeightVal;
      

      state.ftotalcbm = action.payload.ftotalcbm;
     
    }
  }
  
});

export const { set_Total_UnitsVal, set_Total_WeightVal, set_Total_CBM ,set_Shipment_Total } = sharedStateSlice.actions;
export default sharedStateSlice.reducer;