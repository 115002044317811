import React, { useState } from 'react';
import { Table, Pagination } from 'antd';
import styled from 'styled-components';



const PaginationTable = (props) => {
    const handlePageChange = (page) => {
        props.setCurrentPage(page);
    };
    const paginationConfig = {
        current: props.currentPage,
        pageSize: props.pageSize,
        total: props.data.length,
        onChange: handlePageChange,
    };
    const slicedData = props?.data.slice((props.currentPage - 1) * props.pageSize, props.currentPage * props.pageSize);

  return (
    <TableContainer>
      <TableItem 
        rowKey={props.rowKey ? props.rowKey : "id"}
        name={props.name}
        columns={props.columns}
        dataSource={slicedData} 
        scroll={props.scroll ? {x: 1300,} : null}
        pagination={paginationConfig}
        fpadding={props.fpadding}
        showScroll={props.showScroll} 
        className={props.className? props.className : ""} />
    </TableContainer>
  );
};

export default PaginationTable;

const TableContainer = styled.div`
  /* overflow-x: hidden;
  width: 100%; */
  
`

export const TableItem = styled(Table)`
    margin-top: 8px;
    thead[class*="ant-table-thead"] th,thead[class*="ant-table-thead"] td{
        background-color: #fff !important;
        padding: 15px !important;
        text-align: center  !important;
        th:first-child {
            text-align: left  !important;
        }
    }
    thead[class*="ant-table-thead"] th:first-child,thead[class*="ant-table-thead"] td:first-child{
        text-align: left  !important;
    }
    tbody[class*="ant-table-tbody"] tr>td{
        cursor: pointer;
        text-align: center !important;
        color: #3F4254 !important;
        font-weight: 700 !important;
    }
    tbody[class*="ant-table-tbody"] tr>td:first-child{
        text-align: left !important;
    }
    th {
        font-size: 12px !important;
        color: #B5B5C3 !important;
        background-color: #fff !important;
        border-start-start-radius: 0px !important;
        border-bottom: 1px dashed #E4E6EF !important;
        ::before {
            content:none !important;
        }
        /* padding-left: ${({ name })=> name === 'rate_management' ? '0px !important' : 'unset'}; */
        width: ${({ width }) => width ? width : 'unset'};
        &.custome-class {
            border-bottom: none !important;
            width : unset !important;
        }
        &.text-center {
            text-align: center;
        }
        &.text-right {
            text-align: right;
        }
        &.wdth-50 {
            width: 100% !important;
        }
    }
    th:first-child {
        width : '25%';
    }
    
    td {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #3F4254 !important;
        background-color: #fff !important;
        border-start-start-radius: 0px !important;
        border-bottom: 1px dashed #E4E6EF !important;
        font-family: 'Rubik';
        font-style: normal;
        ::before {
            content:none !important;
        }
        padding: ${({ fpadding })=> fpadding ? fpadding : "25px 16px !important"};
        padding-left: ${({ name })=> name === 'rate_management' ? '0px !important' : 'unset'}
        /* padding: 25px 16px !important; */
    }
    &.quote_section {
        th {
            width: 40% !important;
        }
        th:first-child {
            width : 18% !important;
        }
        td {
            width: 45% !important;
        }
        td:first-child {
            width : 18% !important;
        }
    }
    & .ant-table-content {
        &::-webkit-scrollbar {
            /* display: none; */
            display: ${({showScroll})=> showScroll ? 'block': 'none'};
            height: 6px !important;
        }
        & .ant-table-measure-row {
            display: none !important;
        }
    }
    & .ant-table-body {
        &::-webkit-scrollbar {
            /* display: none; */
            display: ${({showScroll})=> showScroll ? 'block': 'none'};
            width: 6px !important;
        }
        & .ant-table-measure-row {
            display: none !important;
        }
    }
    & .ant-table-header {
        table {
            table-layout: unset !important;
            & .ant-table-thead{
                th {
                    width: 50%;
                }
                & .ant-table-cell-scrollbar {
                    display: none;
                }
            }
        }
    }
`