export const getReportLabels = (mode) => {
  let labels = [];
  if (mode === "day") {
    for (var i = 1; i <= 24; i++) {
      labels.push(i);
    }
  } else if (mode === "week") {
    labels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  } else if (mode === "month") {
    // Get the current date
    var currentDate = new Date();
    // Get the current month
    var currentMonth = currentDate.getMonth();
    // Get the number of days in the current month
    var numberOfDays = new Date(
      currentDate.getFullYear(),
      currentMonth + 1,
      0
    ).getDate();

    // Create the array of numbers
    labels = Array.from({ length: numberOfDays }, (_, index) => index + 1);
  } else if (mode === "year") {
    labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }
  return labels;
};

export function getDaysBetweenDates(startDate, endDate) {
  const result = [];
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  while (currentDate <= lastDate) {
    const day = currentDate.getDate().toString().padStart(2, "0");
    result.push(day);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return result;
}
