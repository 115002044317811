import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

function ViewButton(props) {
  return (
    <div>
      <ButtonView onClick={()=> props.handleClick(props.data)}>View</ButtonView>
    </div>
  )
}

export default ViewButton

export const ButtonView = styled(Button)`
    border-radius: 9px;
    background-color: #F5F8FA;
    color: #A1A5B7;
    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    border: none !important;
    :hover {
        color: #A1A5B7 !important;
    }
`