import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Authentication/AuthSlice";
import SignupSlice from "./Authentication/SignupSlice";
import BookingHistorySlice from "./BookingHistory/BookingHistorySlice";
import CommonSlice from "./Common/CommonSlice";
import QueriesSlice from "./Queries/QueriesSlice";
import SearchRatesSlice from "./SearchRates/SearchRatesSlice";
import KycSlice from "./User/KycSlice";
import ProfileSlice from "./User/ProfileSlice";
import OfflinePaymentSlice from "./OfflinePayement/OfflinePaymentSlice";
import CreditLimitSlice from "./User/CreditLimitSlice";
import sharedStateReducer from "./Newslices/sharedStateSlice";
import  set_selectedOption  from "./Newslices/classdescription";
import  setlclpackagedata  from "./Newslices/Lclpackdata";
// import { set_error } from "./Newslices/errordropdown";
export const store = configureStore({
    reducer:{
        userlogin : AuthSlice,
        signup: SignupSlice,
        commonapi: CommonSlice,
        usersection: KycSlice,
        searchrates: SearchRatesSlice,
        queriessate : QueriesSlice,
        bookinghistory : BookingHistorySlice,
        userprofile : ProfileSlice,
        offlinepayment : OfflinePaymentSlice,
        creditlimit : CreditLimitSlice,
        packaging: sharedStateReducer,
        classDescription: set_selectedOption,
        lclpackaging: setlclpackagedata,
        
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })    
})