import React, { Suspense, lazy, useState } from 'react'
import { useSelector } from 'react-redux'
import { getupdateuserheadertitle } from '../../store/Common/CommonSlice'
import { Skeleton } from 'antd'
const WelcomeCreditService = lazy(()=> import('./MyCreditsComponents/WelcomeCreditService'))
const TransactionHistory = lazy(()=> import('./MyCreditsComponents/TransactionHistory'))

const MyCredits = () => {
    const pageTtile = useSelector(getupdateuserheadertitle)
    const [openPaymentFormModal, setOpenPaymentFormModal] = useState(false)
    return (
        <div>
            <div className='flex justify-between items-center py-5 sm:pt-0 md:pt-0 gap-5 flex-wrap'>
                <p className="px-8 py-2.5 sm:px-0 font-bold text-lg leading-5  text-secondary">{pageTtile}</p>
            </div>
            <hr className='border-[#F5F5F5]'/>
            <div className="grid grid-cols-12 gap-6 w-full py-5 sm:px-0 px-8">
                <div className="col-span-12">
                    <Suspense fallback={<Skeleton active/>}>
                        <WelcomeCreditService openPaymentFormModal={openPaymentFormModal} setOpenPaymentFormModal={setOpenPaymentFormModal}/>
                    </Suspense>
                </div>
                <div className="col-span-12">
                    <Suspense fallback={<Skeleton active/>}>
                        <TransactionHistory openPaymentFormModal={openPaymentFormModal} />
                    </Suspense>
                </div>
            </div>

        </div>
    )
}

export default React.memo(MyCredits)