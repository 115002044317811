import { Table } from 'antd'
import React from 'react'
import { RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { savequeryid } from '../../store/Queries/QueriesSlice';

const QueriesList = ({data,loading}) => {
    const [page, setPage] = React.useState(1);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const columns = [
        {
            title:"SI NO",
            key:"index",
            render:(value, item, index) => {
                return ((page - 1) * 10 + index) + 1
            }
        },
        {
            title: 'Freight Type',
            dataIndex: 'cargoType',
            key: 'cargoType',
            filters: [
                {
                    text: 'Air Cargo',
                    value: 'Air Cargo',
                },
                {
                    text: 'Ocean Cargo',
                    value: 'Ocean Cargo',
                }
            ],
            onFilter: (value, record) => record.cargoType.includes(value),
        },
        {
            title: 'Origin',
            key: 'originPort.origin',
            render:(datarow)=> {
                return datarow?.searchquery?.cargoType === 'Air Cargo' ? datarow?.searchquery?.originAirport?.originAirport : datarow?.searchquery?.originShipment?.originShipment
            }
        },
        {
            title: 'Destination',
            key: 'destinationPort.destination',
            render:(datarow)=> {
                return datarow?.searchquery?.cargoType === 'Air Cargo' ? datarow?.searchquery?.destinationAirport?.destinationAirport : datarow?.searchquery?.destinationShipment?.destinationShipment
            }
        },
        {
            title: 'Cargo Ready Date',
            key: 'cargoreadydate',
            render: (record) => {
                return record?.cargoreadydate ? record?.cargoreadydate : 'NA'
            },
        },
        {
            title: 'Query Date',
            key: 'queryDate',
            render:(datarow)=> {
                return dayjs(datarow?.queryDate).format('DD/MM/YYYY')
            }
        },
        {
            title: 'Commodity Type',
            key: 'commoditytype',
            render: (record) => {
                return record?.searchquery?.goodsDetails?.commonditytype?.commonditytype ? record?.searchquery?.goodsDetails?.commonditytype?.commonditytype : 'NA'
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (_, record) => {
                return (
                    <span
                      className={`flex justify-center text-sm font-bold leading-4 ${
                        record.status === "New" ? "text-primary" : record.status === "Quoted" ? 'text-[#A39C06]' : "text-red-700"
                      }`}
                    >
                      {record.status}
                    </span>
                );
            },
            filters: [
                {
                    text: 'New',
                    value: 'New',
                },
                {
                    text: 'Quoted',
                    value: 'Quoted',
                },
                {
                    text: 'Expired',
                    value: 'Expired',
                },
            ],
            onFilter: (value, record) => record.status.includes(value),
        },
        {
            title: '',
            key: 'action',
            render: (record) => {
                return (
                    <div className={`flex justify-center text-sm font-bold leading-4 cursor-pointer `} >
                        <RightOutlined className='text-secondary'/>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <Table 
            rowKey={(record) => record.queryId} 
            columns={columns} 
            dataSource={data?.queryResult?.length > 0 ? data?.queryResult : []} 
            scroll={{x:1024}} 
            loading={loading}
            onRow={(record,i)=>{
                return{
                    onClick: (e)=>{
                        dispatch(savequeryid(record.queryId))
                        navigate('queries-details')
                    }
                }
            }}
            pagination={{
                onChange(current) {
                  setPage(current);
                }
            }}
            />
        </>
    )
}

export default QueriesList