import React from 'react'
import SelectSearch from './SelectSearch'

function HeaderFilter(props) {

  return (
    <div className="flex flex-wrap gap-8">
        <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Carrier</span>
            <SelectSearch value={props.filter?.carrier} setFilter={props.setFilter} name="carrier" />
        </div>
        <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Port</span>
            <SelectSearch value={props.filter?.port} setFilter={props.setFilter} name="port" />
        </div>
        <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Country</span>
            <SelectSearch value={props.filter?.country} setFilter={props.setFilter} name="country" />
        </div>
        <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Sector</span>
            <SelectSearch value={props.filter?.sector} setFilter={props.setFilter} name="sector" />
        </div>
        </div>
  )
}

export default HeaderFilter
