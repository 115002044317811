import React, { useEffect } from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";

function CustomerVerticalBarChart(props) {
    let labels = ["Air India","Vistara","Indigo","Spice","Decan Air","Emirates"]
    // if(props.mode === 'custom_date' && props.state.from_date && props.state.to_date){
    //     labels = getDaysBetweenDates(props.state.from_date,props.state.to_date)
    // }else {
    //     labels =  getReportLabels(props.mode)
    // }
  var barChartData = {
    labels: labels,
    datasets: [
      {
        barPercentage: 1,
        categoryPercentage: 0.3,
        // label: "Minimum",
        backgroundColor: "#9D41F9",
        borderColor: "#9D41F9",
        borderWidth: 1,
        data: [5,6,8,13,9,11],
      },
    ],
  };

  var chartOptions = {
    // maintainAspectRatio: false,
    indexAxis: 'y',
    responsive: true,
    layout: {
        autoPadding: true,
        padding: {
          top: 50
        }
      },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Growth Chart",
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "2023, Volume in TON →",
          font: {
            size: 13,
          },
        },
      },
      
    },
    plugins: {
      legend: {
        position: "top",
        align: "end",
        display: false
      },
      tooltip: {
        enabled: true,
        position: "average",
        yAlign: "bottom",
        bodyAlign: "center",
        intersect: true,
        backgroundColor: "#FFFFFF",
        bodyColor: "#242424",
        titleColor: "#242424",
        borderColor: "rgba(222, 200, 2)",
        borderWidth: "2",
        bodySpacing: "4",
        padding: "14",
        cornerRadius: "5",
        displayColors: false,
        // xAlign: 'bottom'
      },
    },
  };

  return (
    <ChartContainer>
      <BarItem data={barChartData} options={chartOptions} height={100} />
    </ChartContainer>
  );
}

export default CustomerVerticalBarChart;

const ChartContainer = styled.div`
  height: 100%;
`;
const BarItem = styled(Bar)`
  /* height: 400px !important; */
`