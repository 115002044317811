import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const signup = createAsyncThunk('signup', async (values)=>{
    try{    
        const url = 'auth/register'
        console.log(url);
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const verifysignupotp = createAsyncThunk('verifysignupotp', async (values)=>{
    try{
        const url = 'auth/verifyotp'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    signupUser : {},
}

const signupSlice = createSlice({
    name : 'signupslice',
    initialState,
    extraReducers:{
    }
})


export default signupSlice.reducer