import React from 'react'

const MainContent = ({children}) => {
    return (
        <>
            <div className='w-full relative min-h-[calc(100vh_-_232px)] sm:px-5'>
                {children}
            </div>
            <div className='bg-white shadow-cardsection rounded-xl w-full p-5 sm:px-0 text-center bottom-0 right-0 z-10'>
                <p>Copyright © {new Date().getFullYear()} SGate. All rights reserved.</p>
            </div>
        </>
    )
}

export default MainContent