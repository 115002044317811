import React, { Suspense, lazy, useEffect, useState } from "react";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Popover } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import TextError from "../../TextError";
import { useDispatch, useSelector } from "react-redux";
import {
  clearstate,
  getallshipments,
  searchrates,
  searchratestate,
} from "../../../store/SearchRates/SearchRatesSlice";
import { useNavigate } from "react-router-dom";
import inactivefcl from "../../../assets/images/inactive-fcl-icon.svg";
import inactivelcl from "../../../assets/images/inactive-lcl-icon.svg";
import activefcl from "../../../assets/images/active-fcl-icon.svg";
import activelcl from "../../../assets/images/active-lcl-icon.svg";
import { IncoTerms } from "../../../utils/incoTermsDatas";
import { Modal, Skeleton } from "antd";

const OriginShipment = lazy(() => import("./OriginShipment/OriginShipment"));
const DestinationShipment = lazy(() =>
  import("./DestinationShipment/DestinationShipment")
);
const ContainerDetailsFCL = lazy(() =>
  import("./ContainerDetails/ContainerDetailsFCL")
);
const ContainerDetailsLCL = lazy(() =>
  import("./ContainerDetails/ContainerDetailsLCL")
);

const ShipmentRateForm = ({ searchBy, searchFormData }) => {
  const [containerLoad, setContainerLoad] = useState(
    searchFormData?.searchquery?.containerLoad
      ? searchFormData?.searchquery?.containerLoad
      : "FCL"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [formikData, setFormikData] = useState(null);
  const [popForm, setPopForm] = useState();
  const [shipPorts, setShipPorts] = useState();
  const [webopen, setWebOpen] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allShipmentState = useSelector(searchratestate);
  const handleClick = (event, formik, popform) => {
    console.log(formik,formik.values.containerDetails?.packagingDetails?.totalWeight);
    // console.log(
    //   formik.values.containerDetails.containerDetails[0].containerSize
    // );
    console.log(formik.values.containerDetails?.incoTerms);
    console.log(popform);
    setAnchorEl(event.currentTarget);
    setFormikData(formik);
    setPopForm(popform);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  function findflights(values) {
    console.log(values);
    dispatch(searchrates(JSON.stringify(values))).then((res) => {
      navigate("/search-rates/search-rates-result");
    });
  }
  function openmodal(values, msg, type) {
    Modal.confirm({
      title: msg,
      okText: "Proceed",
      cancelButtonProps: {
        style: {
          color: "#FFAF00",
          borderColor: "#FFAF00",
        },
      },
      onOk() {
        if (type === "origin") {
          values.originShipment.originPickup = false;
          values.originShipment.originLocation = "";
          values.originShipment.originLocationMarker = {};
          values.originShipment.originPortMarker = {};
          values.originShipment.originLocationCountry = "";
        } else if (type === "destination") {
          values.destinationShipment.destinationPickup = false;
          values.destinationShipment.destinationLocation = "";
          values.destinationShipment.destinationLocationMarker = {};
          values.destinationShipment.destinationPortMarker = {};
          values.destinationShipment.destinationLocationCountry = "";
        } else if (type === "thirdcountry") {
          values.originShipment.originPickup = false;
          values.originShipment.originLocation = "";
          values.destinationShipment.destinationPickup = false;
          values.destinationShipment.destinationLocation = "";
        }
        console.log(values)
        findflights(values);
      },
      onCancel() {},
      okButtonProps: {
        style: {
          color: "#ffffff",
          backgroundColor: "#FFAF00",
        },
      },
    });
  }
  const onSubmit = (values, setprops) => {
    // if (values.containerDetails.incoTerms !== "Third Country") {
    //   if (values.containerDetails.vaslist === "") {
    //     // setprops.setFieldError(
    //     //   "containerDetails.vaslist",
    //     //   "Container Details is required"
    //     // );
    //     return false;
    //   }}
    //   console.log(values,IncoTerms.imports)
    //   const importData = IncoTerms.imports.find(
    //     (item) => item.name === values.containerDetails.incoTerms
    //   );
    //   console.log("importData",importData);
    //   const importDataOriginSerives = importData?.services?.originServices?.map(
    //     (item) => {
    //       return item?.name;
    //     }
    //   );
    //   console.log("importDataOriginSerives",importDataOriginSerives)
    //   const importDataDestiSerives =
    //     importData?.services?.destinationServices?.map((item) => {
    //       return item?.name;
    //     });
    //     console.log("importDataDestiSerives",importDataDestiSerives)
    //   const exportData = IncoTerms.exports.find(
    //     (item) => item.name === values?.containerDetails?.incoTerms
    //   );


    //   console.log("exportData",exportData);
    //   const exportDataOriginSerives = exportData?.services?.originServices?.map(
    //     (item) => {
    //       return item?.name;
    //     }
    //   );
    //   console.log("exportDataOriginSerives",exportDataOriginSerives)
    //   const exportDataDestiSerives =
    //     exportData?.services?.destinationServices?.map((item) => {
    //       console.log(item?.name)
    //       return item?.name;
    //     });
    //     console.log("exportDataDestiSerives",exportDataDestiSerives)

    //   if (
    //     importData &&
    //     importDataOriginSerives?.includes("Origin Transportation") &&
    //     values.containerDetails.vaslist.originServices.includes(
    //       "Origin Transportation"
    //     )&& values.originShipment.originPickup === false
    //   )
    //   //  {
    //   //   // && values.originShipment.originPickup === false

    //   //   setprops.setFieldError(
    //   //     "originShipment.originLocation",
    //   //     "Origin Location and Origin Pickup are required"
    //   //   );
    //   //   return false;
    //   // }
    //   {    console.log(  importData ,
    //     importDataOriginSerives?.includes("Origin Transportation") ,
    //     values.containerDetails.vaslist.originServices.includes)
    //     openmodal(values, "Origin Location and Origin Pickup are required", "origin");
    //     return false;
    //   }
    //   if (
    //     importData && 
    //     importDataDestiSerives?.includes("Destination Transportation") &&
    //     values?.containerDetails?.vaslist?.destinationServices.includes(
    //       "Destination Transportation"
    //     )&& values.destinationShipment.destinationPickup === false
    //   )
    //   //  {
    //   //   // && values.destinationShipment.destinationPickup === false
    //   //   setprops.setFieldError(
    //   //     "destinationShipment.destinationLocation",
    //   //     "Destination Location and Destination Dropoff are required"
    //   //   );
    //   //   return false;
    //   // }
    //   {
    //     console.log(importData ,
    //       importDataDestiSerives?.includes("Destination Transportation"),
    //       values?.containerDetails?.vaslist?.destinationServices.includes(
    //         "Destination Transportation"
    //       ) ,values.destinationShipment.destinationPickup === false)
    //     openmodal(values, "Destination Location and Destination Dropoff are required", "destination");
    //     return false;
    //   }
    //   if (
    //     exportData && 
    //     exportDataOriginSerives?.includes("Origin Transportation") &&
    //     values.containerDetails.vaslist.originServices.includes(
    //       "Origin Transportation"
    //     )&& values.originShipment.originPickup === false
    //   )
    //   //  {
    //   //   // && values.originShipment.originPickup === false
    //   //   setprops.setFieldError(
    //   //     "originShipment.originLocation",
    //   //     "Origin Location and Origin Pickup are required"
    //   //   );
    //   //   return false;
    //   // }
    //   {      
    //     console.log(exportData, 
    //       exportDataOriginSerives?.includes("Origin Transportation") ,
    //       values.containerDetails.vaslist.originServices.includes(
    //         "Origin Transportation"
    //       ), values.originShipment.originPickup === false)
    //     openmodal(values, "Origin Location and Origin Pickup are required", "origin");
    //     return false;
    //   }
    //   if (
    //     exportData &&
    //     exportDataDestiSerives?.includes("Destination Transportation") &&
    //     values.containerDetails.vaslist.destinationServices.includes(
    //       "Destination Transportation"
    //     )&& values.destinationShipment.destinationPickup === false
    //   ) 
    //   // {
    //   //   //&& values.destinationShipment.destinationPickup === false
    //   //   setprops.setFieldError(
    //   //     "destinationShipment.destinationLocation",
    //   //     "Destination Location and Destination Dropoff are required"
    //   //   );
    //   //   return false;
    //   // }
      
    //   {
    //     console.log(exportData ,
    //       exportDataDestiSerives?.includes("Destination Transportation") ,
    //       values.containerDetails.vaslist.destinationServices.includes(
    //         "Destination Transportation"
    //       ), values.destinationShipment.destinationPickup === false
    //     ) 
    //     openmodal(values, "Destination Location and Destination Dropoff are required", "destination");
    //     return false;
    //   }
    //   if (
    //     importData &&
    //     importDataOriginSerives?.includes("Origin Transportation") &&
    //     !values.containerDetails.vaslist.originServices.includes(
    //       "Origin Transportation"
    //     ) &&
    //     values.originShipment.originPickup === true
    //   ) 
    //   // {
    //   //   setprops.setFieldError(
    //   //     "containerDetails.vaslist",
    //   //     "Please check the Origin Transportation"
    //   //   );
    //   //   return false;
    //   // }
    //   {  
    //     console.log( importData,
    //       importDataOriginSerives?.includes("Origin Transportation") ,
    //       !values.containerDetails.vaslist.originServices.includes(
    //         "Origin Transportation"
    //       ) ,
    //       values.originShipment.originPickup === true)
    //     openmodal(values, "Please check the Origin Transportation", "origin");
    //     return false;
    //   }
    //   if (
    //     importData &&
    //     importDataDestiSerives?.includes("Destination Transportation") &&
    //     !values.containerDetails.vaslist.destinationServices.includes(
    //       "Destination Transportation"
    //     ) &&
    //     values.destinationShipment.destinationPickup === true
    //   ) 
    //   // {
    //   //   setprops.setFieldError(
    //   //     "containerDetails.vaslist",
    //   //     "Please check the Destination Transportation"
    //   //   );
    //   //   return false;
    //   // }
      
    //   {
    //     console.log(importData,
    //       importDataDestiSerives?.includes("Destination Transportation"),
    //       !values.containerDetails.vaslist.destinationServices.includes(
    //         "Destination Transportation"
    //       ),
    //       values.destinationShipment.destinationPickup === true)
    //     openmodal(values, "Please check the Destination Transportation", "destination");
    //     return false;
    //   }
    //   if (
    //     exportData &&
    //     exportDataOriginSerives?.includes("Origin Transportation") &&
    //     !values.containerDetails.vaslist.originServices.includes(
    //       "Origin Transportation"
    //     ) &&
    //     values.originShipment.originPickup === true
    //   )
    //   //  {
    //   //   setprops.setFieldError(
    //   //     "containerDetails.vaslist",
    //   //     "Please check the Origin Transportation"
    //   //   );
    //   //   return false;
    //   // }
    //   {
    //     console.log(exportData ,
    //       exportDataOriginSerives?.includes("Origin Transportation") ,
    //       !values.containerDetails.vaslist.originServices.includes(
    //         "Origin Transportation"
    //       ) ,
    //       values.originShipment.originPickup === true)
    //     openmodal(values, "Please check the Origin Transportation", "origin");
    //     return false;
    //   }
    //   if (
    //     exportData &&
    //     exportDataDestiSerives?.includes("Destination Transportation") &&
    //     !values.containerDetails.vaslist.destinationServices.includes(
    //       "Destination Transportation"
    //     ) &&
    //     values.destinationShipment.destinationPickup === true
    //   ) 
    //   // {
    //   //   setprops.setFieldError(
    //   //     "containerDetails.vaslist",
    //   //     "Please check the Destination Transportation"
    //   //   );
    //   //   return false;
    //   // }
    //   {
    //     console.log(exportData ,
    //       exportDataDestiSerives?.includes("Destination Transportation") ,
    //       !values.containerDetails.vaslist.destinationServices.includes(
    //         "Destination Transportation"
    //       ) ,
    //       values.destinationShipment.destinationPickup === true)
    //     openmodal(values, "Please check the Destination Transportation", "destination");
    //     return false;
    //   }
    //   if (
    //     importData &&
    //     !importDataOriginSerives?.includes("Origin Transportation") &&
    //     !values?.containerDetails?.vaslist?.originServices.includes(
    //       "Origin Transportation"
    //     ) &&
    //     values.originShipment.originPickup === true
    //   ) {
    //     console.log(importData ,
    //       !importDataOriginSerives?.includes("Origin Transportation") ,
    //       !values?.containerDetails?.vaslist?.originServices.includes(
    //         "Origin Transportation"
    //       ) ,
    //       values.originShipment.originPickup === true)
    //     openmodal(values, "Origin Transportation not available", "origin");
    //     return false;
    //   }
    //   if (
    //     importData &&
    //     !importDataDestiSerives?.includes("Destination Transportation") &&
    //     !values.containerDetails.vaslist.destinationServices.includes(
    //       "Destination Transportation"
    //     ) &&
    //     values.destinationShipment.destinationPickup === true
    //   ) {
    //     console.log(importData &&
    //       !importDataDestiSerives?.includes("Destination Transportation") &&
    //       !values.containerDetails.vaslist.destinationServices.includes(
    //         "Destination Transportation"
    //       ) &&
    //       values.destinationShipment.destinationPickup === true)
    //     openmodal(
    //       values,
    //       "Destination Transportation not available",
    //       "destination"
    //     );
    //     return false;
    //   }

    //   if (
    //     exportData &&
    //     !exportDataOriginSerives?.includes("Origin Transportation") &&
    //     !values?.containerDetails?.vaslist?.originServices.includes(
    //       "Origin Transportation"
    //     ) &&
    //     values.originShipment.originPickup === true
    //   ) { console.log(exportDataOriginSerives,values.containerDetails.vaslist.originServices, values.originShipment.originPickup)
    //     openmodal(values, "Origin Transportation not available", "origin");
    //     return false;
    //   }
    //   // if (
    //   //   exportData && 
    //   //   !exportDataDestiSerives?.includes("Destination Transportation") &&
    //   //   !values?.containerDetails?.vaslist?.destinationServices.includes(
    //   //     "Destination Transportation"
    //   //   )&& values.destinationShipment.destinationPickup === true
    //   // ) {
    //   //   // && values.destinationShipment.destinationPickup === true
    //   //   openmodal(
    //   //     values,
    //   //     "Destination Transportation not available",
    //   //     "destination"
    //   //   );
    //   //   return false;
    //   // }
    //   console.log(values)
    //   findflights(values);
    // } else {
    //   console.log(values)
    //   findflights(values);

    // }
    // console.log("onsubmitshipmentrate", values);
    findflights(values);
  };
  let ContainerInit = [],
    PackageInt = [];
  if (containerLoad === "FCL") {
    if (
      searchFormData?.searchquery?.containerDetails?.containerDetails?.length >
      0
    ) {
      searchFormData?.searchquery?.containerDetails?.containerDetails?.forEach(
        (item) => {
          console.log(item);
          ContainerInit.push({
            containerSize: item.containerSize,
            containerType: item.containerType,
            containerSubType: item.containerSubType,
            cargoWeight: item.cargoWeight,
            containerCount: item.containerCount,
            // incoTerms:item.incoTerms,
          });
        }
      );
    } else {
      ContainerInit.push({
        containerSize: "20",
        containerType: "Standard Dry",
        containerSubType: "General",
        cargoWeight: "18",
        containerCount: "1",
        // incoTerms:"Third Country"

      });
      console.log("thir",searchFormData?.searchquery?.containerDetails?.incoTerms,searchFormData)
      //earchFormData?.searchquery?.containerDetails?.incoTerms?searchFormData?.searchquery?.containerDetails?.incoTerms: "Third Country";
    }
  } else {
    if (
      searchFormData?.searchquery?.containerDetails?.packagingDetails
        ?.packagingDatas?.length > 0
    ) {
      searchFormData?.searchquery?.containerDetails?.packagingDetails?.packagingDatas?.forEach(
        (item) => {
          PackageInt.push({
            noofPackages: item.noofPackages ,// "1",
            length: item.length,// "100",
            width: item.width ,//"100",
            height: item.height , //|| "100",
            unit: item.unit,
            weight: item.weight,//||"1",
            totalsubweight: item.totalsubweight,
            totalsubvolume: item.totalsubvolume,
            totalsubpackages: item.totalsubpackages,
          });
        }
      );
    } else {
      PackageInt.push({
        noofPackages:"1",
        length: "100",
        width: "100",
        height: "100",
        unit: "CM",
        weight: "1",
        totalsubweight: "",
        totalsubvolume: "",
        totalsubpackages: "",
      });
    }
  }

  const initialValues = {
    cargoType: searchBy,
    containerLoad: containerLoad,
    originShipment: {
      originShipment: searchFormData?.searchquery?.originShipment.originShipment
        ? searchFormData?.searchquery?.originShipment.originShipment
        : "",
      originLocation: searchFormData?.searchquery?.originShipment.originLocation
        ? searchFormData?.searchquery?.originShipment.originLocation
        : "",
      originPickup:
        searchFormData?.searchquery?.originShipment.originPickup === "1"
          ? true
          : false,
      originLocationMarker: searchFormData?.searchquery?.originShipment
        .originLocationMarker
        ? searchFormData?.searchquery?.originShipment.originLocationMarker
        : {},
      originPortMarker: searchFormData?.searchquery?.originShipment
        .originPortMarker
        ? searchFormData?.searchquery?.originShipment.originPortMarker
        : {},
      originLocationCountry: searchFormData?.searchquery?.originShipment
        .originLocationCountry
        ? searchFormData?.searchquery?.originShipment.originLocationCountry
        : "",
    },
    destinationShipment: {
      destinationShipment: searchFormData?.searchquery?.destinationShipment
        .destinationShipment
        ? searchFormData?.searchquery?.destinationShipment.destinationShipment
        : "",
      destinationLocation: searchFormData?.searchquery?.destinationShipment
        .destinationLocation
        ? searchFormData?.searchquery?.destinationShipment.destinationLocation
        : "",
      destinationPickup:
        searchFormData?.searchquery?.destinationShipment.destinationPickup ===
        "1"
          ? true
          : false,
      destinationLocationMarker: searchFormData?.searchquery
        ?.destinationShipment.destinationLocationMarker
        ? searchFormData?.searchquery?.destinationShipment
            .destinationLocationMarker
        : {},
      destinationPortMarker: searchFormData?.searchquery?.destinationShipment
        .destinationPortMarker
        ? searchFormData?.searchquery?.destinationShipment.destinationPortMarker
        : {},
      destinationLocationCountry: searchFormData?.searchquery
        ?.destinationShipment.destinationLocationCountry
        ? searchFormData?.searchquery?.destinationShipment
            .destinationLocationCountry
        : "",
    },
    containerDetails: {
      containerDetails: ContainerInit,
      packagingDetails: {
        hblCount: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.hblCount
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.hblCount
          : 1,
        commodity: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.commodity
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.commodity
          : "General",
        packagingDatas: PackageInt,
        totalPackages: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.totalPackages
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.totalPackages
          : "1",
        totalWeight: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.totalWeight
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.totalWeight
          : "1",
        totalVolume: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.totalVolume
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.totalVolume
          : "1",
        packagecount: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.packagecount
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.packagecount
          : "1",
        packageweight: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.packageweight
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.packageweight
          : "1",
        packagevolume: searchFormData?.searchquery?.containerDetails
          ?.packagingDetails?.packagevolume
          ? searchFormData?.searchquery?.containerDetails?.packagingDetails
              ?.packagevolume
          : "1",
      },
      incoTerms: searchFormData?.searchquery?.containerDetails?.incoTerms
        ? searchFormData?.searchquery?.containerDetails?.incoTerms
        : "",
      vaslist: searchFormData?.searchquery?.containerDetails?.vaslist
        ? searchFormData?.searchquery?.containerDetails?.vaslist
        : "",
      origincargostuffing: searchFormData?.searchquery?.containerDetails
        .origincargostuffing
        ? searchFormData?.searchquery?.containerDetails.origincargostuffing
        : "",
      pickupType: searchFormData?.searchquery?.containerDetails.pickupType
        ? searchFormData?.searchquery?.containerDetails.pickupType
        : "",
      originzipcode: searchFormData?.searchquery?.containerDetails.originzipcode
        ? searchFormData?.searchquery?.containerDetails.originzipcode
        : "",
      originaddress: searchFormData?.searchquery?.containerDetails.originaddress
        ? searchFormData?.searchquery?.containerDetails.originaddress
        : "",
      origincfscargostuffing: searchFormData?.searchquery?.containerDetails
        .origincfscargostuffing
        ? searchFormData?.searchquery?.containerDetails.origincfscargostuffing
        : "",
      adcode: searchFormData?.searchquery?.containerDetails.adcode
        ? searchFormData?.searchquery?.containerDetails.adcode
        : "",
      origincargocurrency: searchFormData?.searchquery?.containerDetails
        .origincargocurrency
        ? searchFormData?.searchquery?.containerDetails.origincargocurrency
        : "",
      origincargovalue: searchFormData?.searchquery?.containerDetails
        .origincargovalue
        ? searchFormData?.searchquery?.containerDetails.origincargovalue
        : "",
      destinationcargohandling: searchFormData?.searchquery?.containerDetails
        .destinationcargohandling
        ? searchFormData?.searchquery?.containerDetails.destinationcargohandling
        : "",
      dropType: searchFormData?.searchquery?.containerDetails.dropType
        ? searchFormData?.searchquery?.containerDetails.dropType
        : "",
      destinationzipcode: searchFormData?.searchquery?.containerDetails
        .destinationzipcode
        ? searchFormData?.searchquery?.containerDetails.destinationzipcode
        : "",
      destinationaddress: searchFormData?.searchquery?.containerDetails
        .destinationaddress
        ? searchFormData?.searchquery?.containerDetails.destinationaddress
        : "",
      destinationcargocurrency: searchFormData?.searchquery?.containerDetails
        .destinationcargocurrency
        ? searchFormData?.searchquery?.containerDetails.destinationcargocurrency
        : "",
      destinationcargovalue: searchFormData?.searchquery?.containerDetails
        .destinationcargovalue
        ? searchFormData?.searchquery?.containerDetails.destinationcargovalue
        : "",
      destinationtypeofstuffing: searchFormData?.searchquery?.containerDetails
        .destinationtypeofstuffing
        ? searchFormData?.searchquery?.containerDetails
            .destinationtypeofstuffing
        : "",
    },
  };
  const validationSchema = Yup.object().shape({
    originShipment: Yup.object().shape({
      originShipment: Yup.string().required("Origin Seaport is required"),
    }),
    destinationShipment: Yup.object().shape({
      destinationShipment: Yup.string().required(
        "Destination Seaport is required"
      ),
    }),
  });
  useEffect(() => {
    if (allShipmentState?.allshipports?.length > 0) {
      let optionData = [];
      allShipmentState?.allshipports?.map((item) => {
        return optionData.push({
          value: `${item.port_name} - (${item.port_code})`,
          label: `${item.port_name} - (${item.port_code})`,
        });
      });
      setShipPorts(optionData);
    } else {
      dispatch(getallshipments());
    }
  }, [dispatch, allShipmentState]);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setWebOpen(false);
      } else {
        setWebOpen(true);
      }
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    handleResize();
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="p-5  grid grid-cols-12 gap-4">
                <div className="col-span-12 flex items-center gap-8">
                  <button
                    onClick={() => {
                      setContainerLoad("FCL");
                      dispatch(clearstate());
                    }}
                    type="button"
                    className={` bg-white font-medium rounded-lg text-sm px-4 py-2 text-center flex items-center justify-center gap-2 border  ${
                      containerLoad === "FCL"
                        ? "text-primary border-primary"
                        : "text-containerload border-containerload"
                    }`}
                  >
                    <img
                      src={containerLoad === "FCL" ? activefcl : inactivefcl}
                      alt="Inactive FCL"
                    />
                    <span className="block sm:hidden">
                      Full Container Load (FCL)
                    </span>
                    <span className="sm:block hidden">FCL</span>
                  </button>
                  <button
                    onClick={() => {
                      setContainerLoad("LCL");
                      dispatch(clearstate());
                    }}
                    type="button"
                    className={`bg-white font-medium rounded-lg text-sm px-4 py-2 text-center flex items-center justify-center gap-2 border ${
                      containerLoad === "LCL"
                        ? "text-primary border-primary"
                        : "text-containerload border-containerload"
                    }`}
                  >
                    <img
                      src={containerLoad === "LCL" ? activelcl : inactivelcl}
                      alt="Inactive LCL"
                    />
                    <span className="block sm:hidden">
                      Less Than Container Load (LCL)
                    </span>
                    <span className="sm:block hidden">LCL</span>
                  </button>
                </div>

                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 col-span-4">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Origin of Shipment <span className="text-red-800">*</span>
                  </p>
                  <div
                    onClick={(e) => handleClick(e, formik, "OriginShipment")}
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm sm:text-xs rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                  >
                    {formik.values.originShipment.originShipment ? (
                      <span className="text-formlabel">
                        {formik.values.originShipment.originShipment}
                      </span>
                    ) : (
                      <span>Select Origin / Search by Port name or code</span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="originShipment.originShipment"
                    component={TextError}
                  />
                  <ErrorMessage
                    name="originShipment.originLocation"
                    component={TextError}
                  />
                </div>

                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 col-span-4">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Destination of Shipment{" "}
                    <span className="text-red-800">*</span>
                  </p>
                  <div
                    onClick={(e) =>
                      handleClick(e, formik, "DestinationShipment")
                    }
                    className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm sm:text-xs rounded-md font-normal w-full px-3 py-3 flex items-center justify-between gap-0"
                  >
                    {formik.values.destinationShipment.destinationShipment ? (
                      <span className="text-formlabel">
                        {formik.values.destinationShipment.destinationShipment}
                      </span>
                    ) : (
                      <span>
                        Select Destination / Search by Port name or code
                      </span>
                    )}
                    <RightOutlined />
                  </div>
                  <ErrorMessage
                    name="destinationShipment.destinationShipment"
                    component={TextError}
                  />
                  <ErrorMessage
                    name="destinationShipment.destinationLocation"
                    component={TextError}
                  />
                </div>
                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-4">
                  <p className="font-semibold text-xs text-formlabel leading-4 my-2">
                    Container Details
                  </p>
                  {containerLoad === "FCL" && (
                    <div
                      onClick={(e) =>
                        handleClick(e, formik, "ContainerDetailsFCL")
                      }
                      className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm sm:text-xs rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                    >
                      {/*
                                                    formik.values.containerDetails.containerDetails && formik.values.containerDetails.containerDetails.length > 0 ?
                                                    <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                                                        <span className="bg-primary px-2.5 py-1 rounded-sm ">containerType:{" "}{formik.values.containerDetails.containerDetails[0].containerType ? formik.values.containerDetails.containerDetails[0].containerType:"genral"}</span>
                                                    <span className="bg-primary px-2.5 py-1 rounded-sm ">containerSize:{" "}{formik.values.containerDetails.containerDetails[0].containerSize ? formik.values.containerDetails.containerDetails[0].containerSize:"20"}</span>
                                                    <span className="bg-primary px-2.5 py-1 rounded-sm ">containerCount:{" "}{formik.values.containerDetails.containerDetails[0].containerCount ? formik.values.containerDetails.containerDetails[0].containerCount:"1"}</span>
                                                        
                                                    </div>
                                                    :
                                                    <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                                                    {/* <span className="bg-primary px-2.5 py-1 rounded-sm ">containerType:{" "}{formik.values.containerDetails.containerDetails[0].containerType ? formik.values.containerDetails.containerDetails[0].containerType:"genral"}</span>
                                                    <span className="bg-primary px-2.5 py-1 rounded-sm ">containerSize:{" "}{formik.values.containerDetails.containerDetails[0].containerSize ? formik.values.containerDetails.containerDetails[0].containerSize:"20"}</span>
                                                    <span className="bg-primary px-2.5 py-1 rounded-sm ">containerCount:{" "}{formik.values.containerDetails.containerDetails[0].containerCount ? formik.values.containerDetails.containerDetails[0].containerCount:"1"}</span>
                                                         */}
                      {/* </div> */}
                      {/* } */}
                      {formik.values.containerDetails.containerDetails &&
                      Array.isArray(
                        formik.values.containerDetails.containerDetails
                      ) &&
                      formik.values.containerDetails.containerDetails.length >
                        0 ? (
                        <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Container Type:{" "}
                            {(formik.values.containerDetails
                              .containerDetails[0] &&
                              formik.values.containerDetails.containerDetails[0]
                                .containerType) ||
                              "genral"}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Container Size:{" "}
                            {(formik.values.containerDetails
                              .containerDetails[0] &&
                              formik.values.containerDetails.containerDetails[0]
                                .containerSize) ||
                              "20"}{" "}FT
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            Container Count:{" "}
                            {(formik.values.containerDetails
                              .containerDetails[0] &&
                              formik.values.containerDetails.containerDetails[0]
                                .containerCount) ||
                              "1"}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">
                            IncoTerm:{" "}
                            {(formik.values.containerDetails
                              .incoTerms &&
                              formik.values.containerDetails.
                                incoTerms) ||
                              "Third Country"}{" "}
                          </span>
                        </div>
                      ) : (
                        <span>Add Container Details</span>
                      )}
                      <RightOutlined />
                    </div>
                  )}
                  {containerLoad === "LCL" && (
                    <div
                      onClick={(e) =>
                        handleClick(e, formik, "ContainerDetailsLCL")

                      }
                      className="relative cursor-pointer bg-white border border-gray-300 text-placeholder text-sm sm:text-xs rounded-md font-normal w-full px-5 py-3 flex items-center justify-between gap-3"
                    >
                     {formik.values.containerDetails.packagingDetails? (
                        <div className="text-white font-normal text-sm leading-4 flex items-center flex-wrap gap-1">
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">Commodity:{" "}
                            {formik.values.containerDetails?.packagingDetails?.commodity}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">B/L Count:{" "}
                            {formik.values.containerDetails?.packagingDetails?.hblCount
}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">Total Packages:{" "}
                            {formik.values.containerDetails?.packagingDetails?.totalPackages
}
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">Total Volume:{" "}
                            {formik.values.containerDetails?.packagingDetails?.totalVolume

}{" "}CBM
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">Total Weight:{" "}
                            {formik.values.containerDetails?.packagingDetails?.totalWeight

}{" "}KG
                          </span>
                          <span className="bg-primary px-2.5 py-1 rounded-sm ">IncoTerms:{" "}

                          {(formik.values.containerDetails
                              .incoTerms &&
                              formik.values.containerDetails.
                                incoTerms) ||
                              "Third Country"}


                          </span>
                        </div>
                      ) : (
                        <span>Add Container Details</span>
                      )}
                      <RightOutlined />
                    </div>
                  )}
                  <ErrorMessage
                    name="containerDetails.vaslist"
                    component={TextError}
                  />
                </div>
                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-12 relative flex items-end w-full justify-end">
                  <button
                    type="submit"
                    className="text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm sm:text-xs px-4 py-3 text-center w-1/4 lg:w-1/2 md:w-1/2 sm:w-full flex items-center justify-center gap-2 shadow-searchbox"
                  >
                    <SearchOutlined />
                    <span>Search</span>
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Popover
        className="w-full overflow-y-scroll"
        PaperProps={{
          style: {
            width:
              (popForm === "ContainerDetailsFCL" ||
                popForm === "ContainerDetailsLCL") &&
              webopen === true
                ? "85%"
                : webopen === false
                ? "100%"
                : "50%",
                boxShadow: "10px 10px 10px 10px gray",
                borderRadius: "16px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {popForm === "OriginShipment" ? (
          <Suspense fallback={<Skeleton active />}>
            <OriginShipment
              formikData={formikData}
              setAnchorEl={setAnchorEl}
              shipPorts={shipPorts}
              allshipports={allShipmentState?.allshipports}
            />
          </Suspense>
        ) : popForm === "DestinationShipment" ? (
          <Suspense fallback={<Skeleton active />}>
            <DestinationShipment
              formikData={formikData}
              setAnchorEl={setAnchorEl}
              shipPorts={shipPorts}
              allshipports={allShipmentState?.allshipports}
            />
          </Suspense>
        ) : popForm === "ContainerDetailsFCL" && containerLoad === "FCL" ? (
          <Suspense fallback={<Skeleton active />}>
            <ContainerDetailsFCL
              formikData={formikData}
              setAnchorEl={setAnchorEl}
              allshipports={allShipmentState?.allshipports}
            />
          </Suspense>
        ) : (
          popForm === "ContainerDetailsLCL" &&
          containerLoad === "LCL" && (
            <Suspense fallback={<Skeleton active />}>
              <ContainerDetailsLCL
                formikData={formikData}
                setAnchorEl={setAnchorEl}
                allshipports={allShipmentState?.allshipports}
              />
            </Suspense>
          )
        )}
      </Popover>
    </>
  );
};

export default ShipmentRateForm;
