import dashboardicon from '../assets/images/menu-icons/dashboard.svg'
import activedashboardicon from '../assets/images/menu-icons/active-dashboard.svg'
import searchrates from '../assets/images/menu-icons/searchrates.svg'
import activesearchrates from '../assets/images/menu-icons/active-searchrates.svg'
import bookinghistory from '../assets/images/menu-icons/booking-history.svg'
import activebookinghistory from '../assets/images/menu-icons/active-booking-history.svg'
import queries from '../assets/images/menu-icons/queries.svg'
import activequeries from '../assets/images/menu-icons/active-queries.svg'
import Dashboard from '../pages/Dashboard'
import SearchRates from '../pages/SearchRates'
import BookingHistory from '../pages/BookingHistory'
import Queries from '../pages/Queries'


const routs = [
    // {
    //     path: '',
    //     name: 'Dashboard',
    //     menuicon: dashboardicon,
    //     menuiconActive: activedashboardicon,
    //     role: ['customer'],
    //     component : Dashboard
    // },
    {
        path: 'search-rates',
        name: 'Search Rates',
        menuicon: searchrates,
        menuiconActive: activesearchrates,
        role: ['customer'],
        component : SearchRates
    },
    {
        path: 'queries',
        name: 'Queries',
        menuicon: queries,
        menuiconActive: activequeries,
        role: ['customer'],
        component : Queries
    },
    {
        path: 'booking-history',
        name: 'Booking History',
        menuicon: bookinghistory,
        menuiconActive: activebookinghistory,
        role: ['customer'],
        component : BookingHistory
    },
    
    
]

export default routs