import React, { useEffect, useState } from 'react'
import sidebarmenus from '../routes/routes';
import {useLocation } from 'react-router-dom';
import NotificationBell from '../components/common/Notifications/NotificationBell';
import DropDownButton from '../components/common/HeaderDropdownMenus/DropDownButton';
import HeaderUserName from '../components/common/HeaderUserName';

const Header = () => {
    const [pageTitle, setPageTitle] = useState('')
    const path = useLocation()
    const user_name = JSON.parse(localStorage.getItem("user"));

    useEffect(()=>{
        function getheadertitle(name) {
            return sidebarmenus.find((item) => `/${item.path}` === name);
        }
        const headername = getheadertitle(path.pathname);
        headername ? setPageTitle(headername?.name) : setPageTitle('')

    },[path])
    
    return (
        <div className='bg-white shadow-cardsection rounded-xl w-full p-5'>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    {
                        pageTitle &&
                        <p className='mx-5 font-bold text-lg leading-5 text-secondary'>{pageTitle}</p>
                    }
                </div>
                <div className="flex items-center gap-5">
                    <HeaderUserName user_name={user_name}/>
                    <NotificationBell/>
                    <DropDownButton user_name={user_name}/>
                </div>
            </div>
        </div>
    )
}

export default Header