import profileicon from '../assets/images/header-menus/profile-icon.svg'
import kycicon from '../assets/images/header-menus/kyc-icon.svg'
import helpicon from '../assets/images/header-menus/help-icon.svg'
import settingsicon from '../assets/images/header-menus/settings-icon.svg'
import termsicon from '../assets/images/header-menus/terms-icon.svg'
import logouticon from '../assets/images/header-menus/logout-icon.svg'
import cardicon from '../assets/images/header-menus/card-pos.svg'

const headermenus = [
    {
        path: 'user/profile',
        slug:'profile',
        name: 'Profile',
        menuicon: profileicon,
        role: ['customer'],
        pagetitle : 'Personal Details'
    },
    {
        path: 'user/kyc',
        slug:'kyc',
        name: 'KYC',
        menuicon: kycicon,
        role: ['customer'],
        pagetitle : 'Company Details'
    },
    {
        path: 'user/help-and-support',
        slug:'help-and-support',
        name: 'Help and Support',
        menuicon: helpicon,
        role: ['customer'],
        pagetitle : 'Send Request Message'
    },
    {
        path: 'user/settings',
        slug:'settings',
        name: 'Settings',
        menuicon: settingsicon,
        role: ['customer'],
        pagetitle : 'Theme'
    },
    {
        path: 'user/terms-and-conditions',
        slug:'terms-and-conditions',
        name: 'Terms and conditions',
        menuicon: termsicon,
        role: ['customer'],
        pagetitle : 'Terms and conditions'
    },
    {
        path: 'user/my-credits',
        slug:'my-credits',
        name: 'My Credits',
        menuicon: cardicon,
        role: ['customer'],
        pagetitle : 'My Credits'
    },
    {
        path: 'logout',
        slug:'logout',
        name: 'Logout',
        menuicon: logouticon,
        role: ['customer'],
        pagetitle : 'Logout'
    }
    
]

export default headermenus