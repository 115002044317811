import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPackages: 0,
  totalWeightVal: 0,
  totalCBM: 0,
};

const lclpackdataSlice = createSlice({
  name: "lclpackaging",
  initialState,
  reducers: {
    setlclpackagedata(state, action) {
      console.log("hi4", action.payload);

      state.totalPackages = action.payload.totalPackages;
      console.log("slice",action.payload.totalPackages);
      state.totalWeightVal = action.payload.totalWeightVal;
      console.log("slice",action.payload.totalWeightVal);
      state.totalCBM = action.payload.totalCBM;
      console.log("slice",action.payload.totalCBM);
    },
  },
});

export const {
    setlclpackagedata
} = lclpackdataSlice.actions;
export default lclpackdataSlice.reducer;
