import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import './utils/antDStyles/customizedantdstyle.scss'
import PageLayout from './layouts/PageLayout';
import ProtectedRoute from './routes/ProtectedRoute';
import Notification from './shared/notification/Notification';
import { getnotificationdata, notificationlist, updatefirebasetoken, updatenotificationcount,} from './store/Common/CommonSlice';
import { Suspense, lazy, useEffect } from 'react';
import { Skeleton, notification } from 'antd';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebase from "./firebase";
import { authtoken } from './store/Authentication/AuthSlice';
const Login = lazy(()=> import('./layouts/Login'));
const LoginForm = lazy(()=> import('./components/Authentication/LoginForm'));
const SignupForm = lazy(()=> import('./components/Authentication/SignupForm'));
const ForgotPasswordForm = lazy(()=> import('./components/Authentication/ForgotPasswordForm'));
const ChangePasswordForm = lazy(()=> import('./components/Authentication/ChangePasswordForm'));

function App() {
    //console.log("process",process.env.REACT_APP_API_BASE_URL);
    const notificationData = useSelector(getnotificationdata);
    const getauthtoken=useSelector(authtoken);
    //console.log("getlogindata",getlogindata);
    const dispatch = useDispatch()
     
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        
        //console.log('Message received. ', payload);
        dispatch(notificationlist(10))
        .then((res)=>{
            if(res?.payload?.data?.statusCode === 200){
                dispatch(updatenotificationcount(res?.payload?.data?.newCount))
                notification.open({
                    message: payload?.notification?.title,
                    description: payload?.notification?.body,
                    onClick: () => {
                      console.log('Notification Clicked!');
                      
                    },
                });
            }
        })
        .catch(err=>{
            console.error(err);
        })
    });

    useEffect(()=>{
        getToken(messaging, { vapidKey: 'BAWZ3S4S_Wpke5TVYSAOU6faC5a63H8IVdIMRc_7HtXjF8mQoe249tr40GM_S7vIMrRdRagDR4Sr5nrKGE-Pr-w' })
        .then((currentToken) => {
            if (currentToken) {
                console.log('token is => ',currentToken);
                dispatch(updatefirebasetoken(currentToken))
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    },[messaging])
    // const messaging = firebase.messaging();
    // useEffect(()=>{
    //     const requestFirebaseNotificationPermission = async () => {
    //         try {
    //           const token = await messaging.getToken();
    //           console.log('ttt => ',token);
    //           return token;
    //         } catch (error) {
    //           console.error("Error obtaining FCM token:", error);
    //         }
    //       };
    //       requestFirebaseNotificationPermission();
    // },[messaging])
    return (
        <>
            <Routes>
        
              <Route path='/auth/*' element={<Suspense fallback={<Skeleton active/>}><Login/></Suspense>}>            
              <Route path='login' index element={<Suspense fallback={<Skeleton active/>}><LoginForm/></Suspense>} />
              <Route path="signup" element={<Suspense fallback={<Skeleton active/>}><SignupForm/></Suspense>}/>
              <Route path="forgotpassword" element={<Suspense fallback={<Skeleton active/>}><ForgotPasswordForm/></Suspense>}/>
              <Route path="changepassword/:id/:token" element={<Suspense fallback={<Skeleton active/>}><ChangePasswordForm/></Suspense>}/>
              <Route path="updatepassword" element={<Suspense fallback={<Skeleton active/>}><ChangePasswordForm/></Suspense>}/>
              <Route path="*" element={<Navigate to="login" replace />} />
              
          </Route>
          <Route path='/*' element={<ProtectedRoute> <PageLayout/> </ProtectedRoute>}/>
            {/* <Route path="*" element={<Navigate to={getauthtoken ? "/" : "/auth/login"} replace />} /> */}
                {/* <Route path='/*' element={<ProtectedRoute> <PageLayout/> </ProtectedRoute>}/> */}
                {/* <Route path='*' element={<Login/>}/> */}
            </Routes>
            {
                (notificationData.success && notificationData.message) &&
                <>
                    <Notification notificationData={notificationData}/>
                </>
            }
        </>
    );
}

export default App;
