import * as XLSX from 'xlsx'

const useExcel = () => {
    const generateExcelFile = (excelsheet, sheetname, filename) => {
        let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(excelsheet);

        XLSX.utils.book_append_sheet(wb, ws, sheetname);
        XLSX.writeFile(wb, filename);
    }
    return { generateExcelFile }
}

export default useExcel