import React, { useState } from "react";
import styled from "styled-components";
import { Radio, DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

const {Button,Group} = Radio
function ReportHeaderTab(props) {
  const [mode, setMode] = useState("day");
  const handleModeChange = (e) => {
    props.setMode(e.target.value);
  };
  const onChange = (date, dateString) => {
    let from_date = dateString[0]
    let to_date = dateString[1]
    props.setState((prevState)=> {
      return {
        ...prevState,
        from_date,
        to_date,
        date_range: date
      }
    })
  };
  return (
    <div>
      <GroupItem
        onChange={handleModeChange}
        value={props.mode}
        style={{
          marginBottom: 8,
        }}
      >
        <ButtonItem value="day">Day</ButtonItem>
        <ButtonItem value="week">Week</ButtonItem>
        <ButtonItem value="month">Month</ButtonItem>
        <ButtonItem value="year">Year</ButtonItem>
        <ButtonItem value="custom_date">Custom Date</ButtonItem>
      </GroupItem>
      {props.mode === 'custom_date'?
        <div className="flex justify-end">
          <RangePicker onChange={onChange} value={props.state.date_range} />
        </div>
      : null}
    </div>
  );
}

export default ReportHeaderTab;

const GroupItem = styled(Group)`  
`
const ButtonItem = styled(Button)`
    &.ant-radio-button-wrapper {
        /* background-color: #DCE1E4 !important; */
        background-color: #F2F7FA !important;
        text-align: center !important;
        span {
            /* font-family: 'Nunito Sans' !important; */
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 16px !important;
            color: #242424 !important;
        }
    }
    &.ant-radio-button-wrapper-checked {
        background-color: #FFFFFF !important;
        border: 1px solid #DCE1E4 !important;
        span {
            color: #001D41 !important;
            font-weight: 600 !important;
        }
        ::before {
            background-color: #DCE1E4 !important;
        }
    }
`