import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const getcreditlimit = createAsyncThunk('getcreditlimit', async ()=>{
    try{
        const url = 'user_credit_limit'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const gettransactionhistory = createAsyncThunk('gettransactionhistory', async ()=>{
    try{
        const url = 'transaction_history'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const getrepaymenthistory = createAsyncThunk('getrepaymenthistory', async ()=>{
    try{
        const url = 'get_all_debit_repayment_lists'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const getcredittransactionlist = createAsyncThunk('getcredittransactionlist', async (count)=>{
    try{
        const url = `get_all_credit_transaction_lists/${count}`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const repaymentverifystatus = createAsyncThunk('repaymentverifystatus', async (values)=>{
    try{
        const url = 'repayment_verify_status'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const bookcargocredit = createAsyncThunk('bookcargocredit', async (values)=>{
    try{
        const url = `book_cargo_credit`
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const createcreditrepaymentorder = createAsyncThunk('createcreditrepaymentorder', async (values)=>{
    try{
        const url = `create_repaymnet_order`
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const rejectedrepayment = createAsyncThunk('rejectedrepayment', async (values)=>{
    try{
        const url = `rejected_repayment`
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const repaymentfailed = createAsyncThunk('repaymentfailed', async (values)=>{
    try{
        const url = 'repayment_failure'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {}

const creditLimitSlice = createSlice({
    name : 'creditlimitslice',
    initialState,
    reducers:{}
})

export default creditLimitSlice.reducer
