import React, { Suspense, lazy, useEffect, useState} from 'react'
import { Skeleton, Tabs } from 'antd';
import { getbookinghistorytabvalue, updatebookinghistorytabvalue } from '../../store/Common/CommonSlice';
import { useDispatch, useSelector } from 'react-redux';

const AirCargoList = lazy(()=> import('./AirCargoList'));
const OceanCargoList = lazy(()=> import('./OceanCargoList'));


const BookedList = ({data,loading}) => {
    const [selectedTab, setSelectedTab] = useState()
    const defaultTab = useSelector(getbookinghistorytabvalue)
    const dispatch = useDispatch()
    useEffect(()=>{
        if(defaultTab){
            setSelectedTab(defaultTab);
        }
    },[defaultTab])
    const items = [
        {
          key: 1,
          label: `Air Cargo`,
          children:<Suspense fallback={<Skeleton active/>}><AirCargoList selectedTab={selectedTab}/></Suspense> ,
        },
        {
          key: 2,
          label: `Ocean Cargo`,
          children:<Suspense fallback={<Skeleton active/>}><OceanCargoList selectedTab={selectedTab}/></Suspense>,
        },
    ];
    return (
        <Tabs
            defaultActiveKey={defaultTab}
            items={items}
            type="card"
            onTabClick={(e)=>{
                setSelectedTab(e)
                dispatch(updatebookinghistorytabvalue(e))
            }}
            tabBarGutter={5}
        />
    )
}

export default React.memo(BookedList)