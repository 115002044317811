import React, { useEffect, useState } from 'react'
import QueriesList from '../components/Queries/QueriesList';
import { useDispatch } from 'react-redux';
import { getallqueries, getkam } from '../store/Queries/QueriesSlice';
import MobQueriesList from '../components/Queries/MobQueriesList';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useExcel from '../utils/generateExcelFile';

const Queries = () => {
    const [queriesData, setQueriesData] = useState()
    const [excelData, setExcelData] = useState([])
    const [kamUser, setKamUser] = useState()
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const exceSheetlData = useExcel();

    // let kamUser = ''
    // if(localStorage.getItem('kam') !== undefined ){ kamUser = JSON.parse(localStorage.getItem('kam'))}
    const downloadExcel = ()=>{
        exceSheetlData.generateExcelFile(
            excelData,
            "Queries",
            "queries.xlsx"
        )
    }
    
    useEffect(()=>{
        setLoading(true)
        dispatch(getallqueries())
        .then(res=>{
            setQueriesData(res?.payload?.data)
            setLoading(false)
        })
        .catch(err=>{
            console.error(err);
        })
        dispatch(getkam())
        .then(res=>{
            setKamUser(res?.payload?.data?.data)
        })
        .catch(err=>{
            console.error(err);
        })
    },[])

    useEffect(()=>{
        if(queriesData?.nodeData?.queryResult?.length > 0){
            let excelsheetdata = []
            queriesData?.nodeData?.queryResult?.map((item,index)=>{
                excelsheetdata.push({
                    "SI NO" : index+1,
                    "Freight Type" : item.cargoType,
                    "Origin" : item?.searchquery?.cargoType === 'Air Cargo' ? item?.searchquery?.originAirport?.originAirport : item?.searchquery?.originShipment?.originShipment,
                    "Destination" : item?.searchquery?.cargoType === 'Air Cargo' ? item?.searchquery?.destinationAirport?.destinationAirport : item?.searchquery?.destinationShipment?.destinationShipment,
                    "Cargo Ready Date" : item?.cargoreadydate ? item?.cargoreadydate : 'NA',
                    "Query Date" : dayjs(item?.queryDate).format('DD/MM/YYYY'),
                    "Commodity Type" : item?.searchquery?.goodsDetails?.commonditytype?.commonditytype ? item?.searchquery?.goodsDetails?.commonditytype?.commonditytype : 'NA',
                    "Status" : item.status,

                })
            })
            setExcelData(excelsheetdata)
        }
    },[queriesData])
    return (
        <>
        {
            <div className="flex gap-5 w-full flex-col">
                <div className="w-full flex items-center justify-end">
                    <button disabled={excelData.length === 0} onClick={downloadExcel} type="button" className="text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm px-4 py-3 text-center flex items-center justify-center gap-2 shadow-searchbox">
                        <DownloadOutlined />
                        <span>Download Queries</span>
                    </button>
                </div>
                <div className="w-full rounded-2xl shadow-cardsection bg-white sm:hidden">
                    <QueriesList data={queriesData?.nodeData} loading={loading}/>
                </div>
                {
                    kamUser &&
                    <div className="w-full rounded-2xl shadow-cardsection bg-white">
                        <div className="grid grid-cols-12 gap-6 p-8 sm:p-5 text-secondary">
                            <div className="sm:col-span-12 md:col-span-6 col-span-4 flex justify-start">
                                <p className="text-sm font-normal leading-4">Connect with our Key managers to get latest rates for your shipment.</p>
                            </div>
                            <div className="sm:col-span-12 md:col-span-6 col-span-4 flex justify-center">
                                <p className="text-sm leading-4 font-bold">{kamUser?.full_name}</p>
                            </div>
                            
                                <div className="sm:col-span-12 md:col-span-6 col-span-4 flex justify-end md:justify-start sm:justify-center">
                                    <div className="text-sm font-normal leading-4 flex flex-col items-start gap-4 sm:w-full">
                                        <div className="flex gap-5 sm:justify-center w-full">
                                            <p className="">Email</p>
                                            <p className="">{kamUser?.email}</p>
                                        </div>
                                        {
                                            (kamUser?.country_code && kamUser?.phone_number) &&
                                            <div className="flex gap-5 sm:justify-center w-full">
                                                <p className="">Phone</p>
                                                <p className="">+{kamUser?.country_code} {kamUser?.phone_number}</p>
                                            </div>
                                        }
                                        {
                                            (kamUser?.wcountrycode && kamUser?.whatsapp) &&
                                            <div className="flex gap-5 sm:justify-center w-full">
                                                <p className="">Whatsapp</p>
                                                <p className="">+{kamUser?.wcountrycode} {kamUser?.whatsapp}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                }
                <div className="hidden sm:flex items-center justify-center w-full h-full">
                    <MobQueriesList data={queriesData?.nodeData} loading={loading}/>
                </div>
            </div>
        }
        </>
    )
}

export default Queries