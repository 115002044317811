import { Button, Skeleton } from "antd";
import React, { Suspense, useState } from "react";
import WelcomeCard from "./WelcomeCard";
import ReportHeader, { IconImg } from "./ReportHeader";
import HeaderFilter from "./HeaderFilter";
import CustomerLineChart from "./CustomerLineChart";
import CustomerLineChartAir from "./CustomerLinearChartAir";
import styled from "styled-components";
import RadioGroups from "./RadioGroups";
import SelectSearch from "./SelectSearch";
import CustomerVerticalBarChart from "./CustomerVerticalBarChart";
import shipIcon from "../../assets/images/dashboard/ship-icon.svg";
import shipIconActive from "../../assets/images/dashboard/active-ship-icon.svg";
import FlightIcon from "../../assets/images/dashboard/flight-icon.svg";
import FlightActive from "../../assets/images/dashboard/active-flight-icon.svg";
import SelectSearchNo from "./SelectSearchNo";
import RateOverViewChart from "./RateOverViewChart";
import PaginationTable from "./PaginationTable";
import ViewButton from "./ViewButton";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
const Dashboard = () => {
  const [mode, setMode] = useState("week");
  const [shipmentmode, setShipmentMode] = useState("week");
  const [shipmentOverViewmode, setShipmentOverViewMode] = useState("week");
  const [loading, setLoading] = useState(false);
  const [volume_unit, setVolumeUnit] = useState("kg");
  const [subTabType, setSubTabType] = useState("Air");
  const [rateOverview, setRateOverView] = useState("Ocean");
  const [shipmentDocView, setShipmentDocView] = useState("Ocean");
  const [invoCurrentPage, setInvoCurrentPage] = useState(1);
  const [docCurrentPage, setDocCurrentPage] = useState(1)
  const [state, setState] = useState({
    from_date: null,
    to_date: null,
    date_range: null,
  });

  const [shipmentOceanFilter, setShipmentOceanFilter] = useState({
    carrier: null,
    port: null,
    country: null,
    sector: null,
  })

  const [shipmentAirFilter, setShipmentAirFilter] = useState({
    carrier: null,
    port: null,
    country: null,
    sector: null,
  })

  const [shipmentCompleteFilter, setShipmentCompleteFilter] = useState({
    carrier: null,
    port: null,
    country: null,
    sector: null,
  })

  const [rateFilter, setRateFilter] = useState({
    carrier: null,
    port: null,
  })


  const handleRadioClick = (value, name) => {
    setSubTabType(value);
  };

  const InvoiceView = (record) => {
    console.log(record);
  };

  const shipmentDocmentView = (record) => {
    console.log(record);
  };

  const sub_filter = [
    {
      id: "Air",
      name: "Air",
    },
    {
      id: "Ocean",
      name: "Ocean",
    },
  ];

  const invoice_columns = [
    {
      title: "NEW UPDATES",
      dataIndex: "invoice",
      render: (text, record) => <span className="text-lightGrey">{text}</span>,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record) => (
        <CustomeButton
          className="short-button"
          onClick={() => InvoiceView(record)}
          size="large"
        >
          View
        </CustomeButton>
      ),
    },
  ];

  const invoice_data = [
    {
      invoice: "Invoice 1",
    },
    {
      invoice: "Invoice 2",
    },
    {
      invoice: "Invoice 3",
    },
    {
      invoice: "Invoice 4",
    },
    {
      invoice: "Invoice 5",
    },
  ];

  const shipmentDocColumn = [
    {
      title: "Shipping ID",
      dataIndex: "shipping_id",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "ORIGIN ",
      dataIndex: "origin",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "DESTINATION ",
      dataIndex: "destination",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "TYPE",
      dataIndex: "type",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (text, record) => <Status text={text}>{text}</Status>,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record) => (
        <ViewButton handleClick={shipmentDocmentView} data={record} />
      ),
    },
  ];

  const shipmentDocData = [
    {
      shipping_id: "8415sgTY",
      origin: "Origin  code",
      destination: "Destination code",
      type: "LCL",
      status: "New",
    },
    {
      shipping_id: "8415sgTY",
      origin: "Origin  code",
      destination: "Destination code",
      type: "LCL",
      status: "New",
    },
    {
      shipping_id: "8415sgTY",
      origin: "Origin  code",
      destination: "Destination code",
      type: "FCL",
      status: "New",
    },
  ];


  return (
    <div className="col-span-12">
      <Suspense fallback={<Skeleton active />}>
        <WelcomeCard />
      </Suspense>
      <Skeleton active loading={loading}>
        <div className="bg-white shadow-md rounded-3xl mt-9">
          <ReportHeader
            title="Shipment Overview"
            description="Volume  V/S Time"
            show_tab={true}
            mode={shipmentmode}
            setMode={setShipmentMode}
            setState={setState}
            state={state}
          />
          <div className="p-10">
            <div className="mb-4">
              <span className="text-mainYellow text-[18px] font-bold">
                Ocean Only
              </span>
            </div>
            <HeaderFilter filter={shipmentOceanFilter} setFilter={setShipmentOceanFilter} />
            <div className="flex justify-between items-center">
              <CustomerLineChart
                mode={shipmentmode}
                state={state}
                type="cbm"
                title="CBM Graph"
              />
              <CustomerLineChart
                mode={shipmentmode}
                state={state}
                type="teu"
                title="TEU Graph"
              />
            </div>
            <div className="flex gap-5 mt-7 flex-wrap">
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TEU
                </span>
                <p className="text-[14px] text-base mt-2">Total Shipment</p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TEU
                </span>
                <p className="text-[14px] text-base mt-2">Total FCL</p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  CBM
                </span>
                <p className="text-[14px] text-base mt-2">Total LCL</p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TEU
                </span>
                <p className="text-[14px] text-base mt-2">
                  Average TEU /Shipment
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  INR
                </span>
                <p className="text-[14px] text-base mt-2">
                  Total Cost Incurred
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black"></span>
                <p className="text-[14px] text-base mt-2">
                  Average Cost / FCL TEU
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  INR
                </span>
                <p className="text-[14px] text-base mt-2">
                  Average Cost / LCL CBM
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-3xl mt-9">
          <div className="p-10">
            <div className="mb-4">
              <span className="text-mainYellow text-[18px] font-bold">
                AIR Only
              </span>
            </div>
            <HeaderFilter filter={shipmentAirFilter} setFilter={setShipmentAirFilter} />
            <div className="flex items-start gap-8 mt-8">
              <span className="mt-1">Volume Unit</span>
              <div>
                <div className="flex items-end gap-2 mb-2">
                  <VolumeUnit
                    active={volume_unit === "kg"}
                    className="py-1 px-5"
                    onClick={() => setVolumeUnit("kg")}
                  >
                    KGS
                  </VolumeUnit>
                  <VolumeUnit
                    active={volume_unit === "ton"}
                    className="py-1 px-3"
                    onClick={() => setVolumeUnit("ton")}
                  >
                    M.TON
                  </VolumeUnit>
                </div>
                <span className="text-[12px]">1000kgs = 1 M</span>
              </div>
            </div>

            <CustomerLineChartAir mode={shipmentmode} state={state} />
            <div className="flex gap-5 mt-7 flex-wrap">
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TON
                </span>
                <p className="text-[14px] text-base mt-2">
                  Total Air Shipments
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black"></span>
                <p className="text-[14px] text-base mt-2">
                  Total Air Cargo Moved
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TON
                </span>
                <p className="text-[14px] text-base mt-2">
                  Average Cargo Weight per Shipment
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TON
                </span>
                <p className="text-[14px] text-base mt-2">
                  Average Daily Cargo Moved
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  TON
                </span>
                <p className="text-[14px] text-base mt-2">
                  Total Cost Incurred
                </p>
              </div>
              <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
                <span className="font-medium text-2xl text-black">1,362</span>
                <span className="ml-3 font-medium text-base text-black">
                  INR
                </span>
                <p className="text-[14px] text-base mt-2">
                  Average Cost per KG
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-3xl mt-9">
          <ReportHeader
            title="Shipment Overview"
            description="Volume  V/S Time- In area /port/carrier"
            show_tab={true}
            mode={shipmentOverViewmode}
            setMode={setShipmentOverViewMode}
            setState={setState}
            state={state}
          />
          <div className="p-10">
            <div className="mb-4 flex items-center justify-between mb-6">
              <span className="text-mainYellow text-[18px] font-bold">
                Complete Overview
              </span>
              <div>
                <RadioGroups
                  datas={sub_filter}
                  value={subTabType}
                  handleClick={handleRadioClick}
                  name="air"
                />
              </div>
              <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
                <span>Select Year</span>
                <SelectSearch />
              </div>
            </div>
            <HeaderFilter filter={shipmentCompleteFilter} setFilter={setShipmentCompleteFilter} />
            <div className="flex items-start gap-8 mt-8">
              <span className="mt-1">Volume Unit</span>
              <div>
                <div className="flex items-end gap-2 mb-2">
                  <VolumeUnit
                    active={volume_unit === "kg"}
                    className="py-1 px-5"
                    onClick={() => setVolumeUnit("kg")}
                  >
                    KGS
                  </VolumeUnit>
                  <VolumeUnit
                    active={volume_unit === "ton"}
                    className="py-1 px-3"
                    onClick={() => setVolumeUnit("ton")}
                  >
                    M.TON
                  </VolumeUnit>
                </div>
                <span className="text-[12px]">1000kgs = 1 M</span>
              </div>
            </div>
            <CustomerVerticalBarChart mode={shipmentOverViewmode} state={state} />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="bg-white shadow-md rounded-3xl mt-9 w-2/3">
            <ReportHeader
              title="Rate  Overview"
              description="Carrier wise"
              show_tab={true}
              mode={mode}
              setMode={setMode}
              setState={setState}
              state={state}
              short_head={true}
            />
            <div className="px-10 py-5">
              <div className="flex gap-3">
                {rateOverview === "Ocean" ? (
                  <div className="border border-solid border-mainYellow rounded-lg p-2">
                    <IconImg src={shipIconActive} />
                  </div>
                ) : (
                  <div
                    onClick={() => setRateOverView("Ocean")}
                    className="border border-solid border-darkGrey rounded-lg p-2 cursor-pointer"
                  >
                    <IconImg src={shipIcon} />
                  </div>
                )}

                {rateOverview === "Air" ? (
                  <div className="border border-solid border-mainYellow rounded-lg p-2">
                    <IconImg src={FlightActive} />
                  </div>
                ) : (
                  <div
                    onClick={() => setRateOverView("Air")}
                    className="border border-solid border-darkGrey rounded-lg p-2 cursor-pointer"
                  >
                    <IconImg src={FlightIcon} />
                  </div>
                )}
              </div>
              <div className="flex flex-wrap gap-8 mt-4">
                <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
                  <span>Select Carrier</span>
                  <SelectSearchNo value={rateFilter?.carrier} setFilter={setRateFilter} name="carrier" />
                </div>
                <div className="flex items-center gap-8 sm:w-1/2 md:w-1/3">
                  <span>Select Port</span>
                  <SelectSearchNo value={rateFilter?.port} setFilter={setRateFilter} name="port" />
                </div>
                <RateOverViewChart mode={mode} state={state} />
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-3xl mt-9 w-1/3">
            <div className="px-4 pt-10 pb-0">
              <span className="text-[24px] font-semibold text-[#181C32]">
                Invoices
              </span>
            </div>
            <div>
              <PaginationTable
                currentPage={invoCurrentPage}
                setCurrentPage={setInvoCurrentPage}
                pageSize={10}
                columns={invoice_columns}
                data={invoice_data}
              />
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-3xl mt-7">
          <ReportHeader
            title="Shipment Documents"
            description="All available documents"
            show_tab={true}
            mode={mode}
            setMode={setMode}
            setState={setState}
            state={state}
            short_head={true}
            show_switch_icon={true}
            setSwitchView={setShipmentDocView}
            switchView={shipmentDocView}
          />
          <div className="px-6 py-1">
            <PaginationTable
              currentPage={docCurrentPage}
              setCurrentPage={setDocCurrentPage}
              pageSize={10}
              columns={shipmentDocColumn}
              data={shipmentDocData}
            />
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default Dashboard;

const Status = styled.span`
  color: ${({ text }) => (text === "New" ? "#FFAF00" : "#1e0e0e")};
`;
const VolumeUnit = styled.div`
  border: ${({ active }) => (active ? "1px solid #FFAF00" : "none")};
  box-shadow: ${({ active }) =>
    active ? "none" : "-3px 3px 50px 5px rgba(0, 0, 0, 0.12)"};
  cursor: pointer;
  font-size: 14px;
  color: ${({ active }) => (active ? "#FFAF00" : "#979797")};
  font-weight: ${({ active }) => (active ? "700" : "400")};
  border-radius: 8px;
`;

export const CustomeButton = styled(Button)`
  color: #ffaf00;
  border-color: #ffaf00;
  font-size: 14px !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  :hover {
    color: #020100c1 !important;
    border-color: #020100c1 !important;
    img {
      filter: grayscale(100%) !important;
    }
  }
  img {
    margin: 0px 10px 4px 0px;
  }
  &.short-button {
    padding: 2px 8px !important;
  }
`;
