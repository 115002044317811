import React, { memo, useEffect } from 'react'
import { notification } from 'antd'
const getNotificationStyle = type => {
    return {
      success: {
        color: 'rgba(0, 0, 0, 1)',
        border: '1px solid #b7eb8f',
        backgroundColor: '#f6ffed'
      },
      warning: {
        color: 'rgba(0, 0, 0, 1)',
        border: '1px solid #ffe58f',
        backgroundColor: '#fffbe6'
      },
      error: {
        color: 'rgba(0, 0, 0, 1)',
        border: '1px solid #ffa39e',
        backgroundColor: '#fff1f0'
      },
      info: {
        color: 'rgba(0, 0, 0, 1)',
        border: '1px solid #91d5ff',
        backgroundColor: '#e6f7ff'
      }
    }[type]
}
const Notification = memo(({notificationData}) => {
    const [api, contextHolder] = notification.useNotification();
    
    useEffect(()=>{
        const openNotificationWithIcon = (notificationData) => {
            api[notificationData.success]({
                message:notificationData.message,
                //closeIcon:'',
                style: getNotificationStyle(notificationData.success),
                placement:'top',
                duration :2
            });
          };
        openNotificationWithIcon(notificationData)
        return ()=>{
        }
    },[notificationData])
    return(
        <>
        {contextHolder}
        </>
    )
})

export default Notification