import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const addprofiledetails = createAsyncThunk('addprofiledetails', async (values)=>{
    try{
        const url = 'edit_profile'
        const response = await httpinstance.put(url,values)
        if(response?.data?.statusCode === 200){
            localStorage.setItem("user", JSON.stringify(response.data?.data));
        }
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {}

const profileSlice = createSlice({
    name : 'profileslice',
    initialState,
    reducers:{}
})

export default profileSlice.reducer
