import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile } from "../../axios/api"

export const uploaddocument = createAsyncThunk('uploaddocument', async (values)=>{
    try{
        const url = 'multi_file_upload'
        const response = await httpinstancefile.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const deletedocument = createAsyncThunk('deletedocument', async (values)=>{
    try{
        const url = 'multi_file_upload_delete'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const addkycdetails = createAsyncThunk('addkycdetails', async (values)=>{
    try{
        const url = 'kycadd'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const getkycdetails = createAsyncThunk('getkycdetails', async ()=>{
    try{
        const url = 'get_kyc'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const kycdocstatus = createAsyncThunk('kycdocstatus', async ()=>{
    try{
        const url = 'user_verification_status'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
const initialState = {
    kycdetails:{},
    gotokycpage : false,
    verification_status : '',
    queriesDetailPageData : {},
}

const kycSlice = createSlice({
    name : 'kycslice',
    initialState,
    reducers:{
        updategotokyc:(state,{payload})=>{
            state.gotokycpage = payload
        },
        savequeries:(state,{payload})=>{
            state.queriesDetailPageData = payload
        }
    },    
    extraReducers:(builder)=>{
        builder.addCase(getkycdetails.fulfilled,(state, {payload})=>{
            return {...state,kycdetails:payload?.data}
        })
        builder.addCase(kycdocstatus.fulfilled,(state, {payload})=>{
            return {...state,verification_status:payload?.data.verification_status}
        })
    }
})
export const { updategotokyc } = kycSlice.actions
export const { savequeries } = kycSlice.actions
export const getkycdetailsdtata = state => state.usersection.kycdetails;
export const gotokycpageval = state => state.usersection.gotokycpage;
export const getsavedqueries = state => state.usersection.queriesDetailPageData;
export const verificationstatus = state => state.usersection.verification_status;
export default kycSlice.reducer