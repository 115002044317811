import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const getairbookinghistory = createAsyncThunk('getairbookinghistory', async ()=>{
    try{
        const url = 'bookinghistory_for_air'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const getoceanbookinghistory = createAsyncThunk('getoceanbookinghistory', async ()=>{
    try{
        const url = 'bookinghistory_for_ocean'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const getsinglebookingdata = createAsyncThunk('getsinglebookingdata', async (values)=>{
    try{
        const url = 'single_booking_data'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    bookinghistorydata : {},
    bookingDetailPageData : {},
}

const bookingHistorySlice = createSlice({
    name : 'bookinghistoryslice',
    initialState,
    extraReducers:{
    }
})

export const getsavedbookingdata = state => state.bookinghistory.bookingDetailPageData;
export default bookingHistorySlice.reducer