import { RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Skeleton } from "antd";
import { useDispatch } from "react-redux";

const WelcomeCard = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Skeleton active loading={loading}>
        <div className="w-full rounded-2xl shadow-creditcard bg-creditcard text-secondary p-6 my-5 relative after:content-[url('assets/images/welcome-creditcard-after.svg')] after:right-0 after:top-0 after:absolute before:content-[url('assets/images/cardBgImg.svg')] before:left-[22%] before:bottom-[-20px] before:absolute">
          <p className="text-3xl font-semibold leading-9 tracking-tight relative z-10">
          Welcome to SLS 100X!
          </p>
          <p className="mt-2 text-[#464646] font-normal">
            Connecting Dots Globally .
          </p>
          <div className="flex flex-col items-start mt-[60px] sm:my-5 gap-5 relative">
            <div className="flex w-full items-end justify-between flex-wrap sm:gap-10 gap-5">
              <div className="flex items-center flex-wrap gap-y-5 gap-x-16">
                <div>
                  <p className="text-sm font-semibold leading-4 tracking-tight mb-2">
                    Assigned Credit
                  </p>
                  <p className="text-3xl font-semibold leading-9 tracking-tight">
                    ₹ 70,000.00
                  </p>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <div className="flex w-full items-center justify-between gap-x-16">
                    <span className="text-sm font-normal">
                      Credit limit exhausted{" "}
                    </span>
                    <span className="text-sm font-semibold">₹ 2,20,000</span>
                  </div>
                  <div className="flex w-full items-center justify-between gap-x-16">
                    <span className="text-sm font-normal">
                      Available limit left{" "}
                    </span>
                    <span className="text-sm font-semibold">₹ 20,000</span>
                  </div>
                </div>
              </div>
              <Link
                to=""
                className="flex items-center gap-3 cursor-pointer hover:text-primary"
              >
                <p className="font-medium text-sm">View Details</p>
                <RightOutlined />
              </Link>
            </div>
          </div>
        </div>
      </Skeleton>
    </>
  );
};

export default React.memo(WelcomeCard);
