import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { getDaysBetweenDates, getReportLabels } from '../../utils/dashboardFunctions';

const CustomerLineChart = (props) => {
  let labels = []
  if(props.mode === 'custom_date' && props.state.from_date && props.state.to_date){
    labels = getDaysBetweenDates(props.state.from_date,props.state.to_date)
  }else {
    labels =  getReportLabels(props.mode)
  }

  function getGradient(ctx) {
    var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, '#ef9a9a')
    gradient.addColorStop(1, '#FFFFFF')
    return gradient
    }
  
  // console.log(labels);
  let datasets = [
    {
      label: 'TEU',
      data: [2, 6, 12, 6, 9, 11,3],
      borderColor: '#00FFFF',
      backgroundColor: '#00FFFF',
      fill: false,
    },
    {
      label: 'CBM',
      data: [5, 8, 3, 7, 6, 9,4],
      borderColor: '#FFE500',
      backgroundColor: '#FFE500',
      fill: true,
      // backgroundColor: function(context) {
      //   const chart = context.chart;
      //   const {ctx, chartArea} = chart;

      //   if (!chartArea) {
      //     // This case happens on initial chart load
      //     return;
      //   }
      //   return getGradient(ctx);
      // },
    },
  ]
  if(props.type === "cbm"){
    datasets = [
      {
        label: 'CBM',
        data: [5, 8, 3, 7, 6, 9,4],
        borderColor: '#FFE500',
        backgroundColor: '#FFE500',
        fill: true,
      },
    ]
  }else if(props.type === "teu"){
    datasets = [
      {
        label: 'TEU',
        data: [2, 6, 12, 6, 9, 11,3],
        borderColor: '#00FFFF',
        backgroundColor: '#00FFFF',
        fill: false,
      },
    ]
  }

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const chartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: 'point',
        intersect: true
    },
    elements: {
      point: {
        hoverRadius: 6,
      },
    },
    layout: {
      autoPadding: true,
      padding: {
        top: 50
      }
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Volume →',
          font: {
            size: 15
          }
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time 2023 →',
          font: {
            size: 15,
          },
          padding: {
            top: 20,
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
      },
      title: {
        display: true,
        text: props.title? props.title : "",
        color: '#FFAF00',
        align: 'start',
        font: {
            weight: 'bold',
            size: 16
        }
      },
      tooltip: {
        enabled: true,
        position: 'average',
        yAlign: 'bottom',
        bodyAlign: 'center',
        intersect: true,
        backgroundColor: '#FFFFFF',
        bodyColor: '#242424',
        titleColor: '#242424',
        borderColor: 'rgba(222, 200, 2)',
        borderWidth: '2',
        bodySpacing: '4',
        padding: '14',
        cornerRadius: '5',
        displayColors: false,
        // xAlign: 'bottom'
      }
    }
  };

  return (
    <ChartContainer>
      <LineItem data={chartData} options={chartOptions} height={200} />
    </ChartContainer>
  );
};

export default CustomerLineChart;

const LineItem = styled(Line)`
  width: 98% !important;
  /* canvas {
    height: 500px !important;
  } */
`

const ChartContainer = styled.div`
  width: 50%;
  border-bottom: 1px solid #DCE1E4;
  padding-bottom: 25px;
`